import { BlockStack, Button, Card, Checkbox, ContextualSaveBar, DescriptionList, Divider, FormLayout, Grid, InlineGrid, Page, Select, Text, TextField } from '@shopify/polaris';
import { useField, useForm } from '@shopify/react-form';
import QuickUploadImage from 'components/oneclick-upload-image';
import QuickSearchBank from 'components/quickSearchBank';
import SkeletonLoading from 'components/skeletonPageLoading';
import { useNotification } from 'context/NotificationContext';
import { useSettings } from 'context/SettingsContext';
import QuickSearchEmailSetting from 'entities/email_setting/quick_search_email_setting';
import QuickSearchEmailTemplate from 'entities/webmail/webmail_search_email_template';
import __ from 'languages/index';
import { useClearAllSession, useSendTestEmail, useUpdateSetting } from 'queries/setting.query';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import helpers from '../../helpers';
export default function Settings() {
  const {
    addNotification
  } = useNotification();
  const {
    settings: entities,
    loading
  } = useSettings();
  const {
    mutateAsync: updateEntity,
    error,
    isSuccess: updateSuccess,
    isPending: updating
  } = useUpdateSetting();

  /** Hết code lấy user role ra ... */
  const useFields = {
    admin_email: useField<string>({
      value: entities?.admin_email ?? '',
      validates: []
    }),
    email_default_template: useField<string>({
      value: entities?.email_default_template ?? '',
      validates: []
    }),
    email_default_setting: useField<string>({
      value: entities?.email_default_setting ?? '',
      validates: []
    }),
    site_logo: useField<string>({
      value: entities?.site_logo ?? '',
      validates: []
    }),
    site_name: useField<string>({
      value: entities?.site_name ?? '',
      validates: []
    }),
    site_slogan: useField<string>({
      value: entities?.site_slogan ?? '',
      validates: []
    }),
    site_main_address_1: useField<string>({
      value: entities?.site_main_address_1 ?? '',
      validates: []
    }),
    site_main_address_2: useField<string>({
      value: entities?.site_main_address_2 ?? '',
      validates: []
    }),
    site_main_address_district: useField<string>({
      value: entities?.site_main_address_district ?? '',
      validates: []
    }),
    site_main_address_city: useField<string>({
      value: entities?.site_main_address_city ?? '',
      validates: []
    })
  };
  const {
    fields,
    submit,
    submitting,
    dirty,
    reset: resetForm,
    submitErrors,
    makeClean
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        await updateEntity({
          admin_email: values.admin_email,
          email_default_template: values.email_default_template,
          email_default_setting: values.email_default_setting,
          site_logo: values.site_logo,
          site_name: values.site_name,
          site_slogan: values.site_slogan,
          site_main_address_1: values.site_main_address_1,
          site_main_address_2: values.site_main_address_2,
          site_main_address_district: values.site_main_address_district,
          site_main_address_city: values.site_main_address_city
        });
      } catch (e: any) {
        if (e.params !== undefined) {
          if (e.params.field !== undefined) {
            useFields[e.params.field].setError(__('check_this_field_again'));
          }
        }
      }
      return {
        status: 'success'
      };
    }
  });
  const {
    mutateAsync: clearAllSession
  } = useClearAllSession();
  const {
    mutateAsync: sendTestEmail
  } = useSendTestEmail();

  /**
   * Notification
   */

  useEffect(() => {
    if (error) {
      addNotification('error', error.message);
    }
  }, [error]);
  useEffect(() => {
    if (updateSuccess) addNotification('info', __('updated_successfully'));
  }, [updateSuccess]);
  const [sendingEmailTesting, setSendingEmailTesting] = useState(false);
  const SendTestEmail = useCallback(async () => {
    setSendingEmailTesting(true);
    submit();
    try {
      await helpers.sleep(1000);
      await sendTestEmail();
    } catch (e) {}
    setSendingEmailTesting(false);
  }, []);
  const [uploadError, setUploadError] = useState(null);
  return <>
      <Helmet>
        <title>Cài đặt chung</title>
      </Helmet>
      {loading ? <SkeletonLoading /> : <Page title="Cài đặt chung">
          <DescriptionList items={[{
        term: 'Thông tin chung',
        description: <Card>
                    <BlockStack>
                      <InlineGrid columns={['oneThird', 'twoThirds']} gap={'200'}>
                        <div className="d1">
                          <QuickUploadImage onSuccess={e => {
                  useFields.site_logo.onChange(e.media_url);
                }} onError={e => setUploadError(e)} title="Click để tải logo lên" placeholder={helpers.getMediaLink(useFields.site_logo.value)} />
                          <Text as="p" tone="critical">
                            {uploadError}
                          </Text>
                        </div>
                        <div className="d2">
                          <TextField label="Tên công ty" autoComplete="off" {...fields.site_name} />

                          <TextField label="Slogan" autoComplete="off" {...fields.site_slogan} />
                        </div>
                      </InlineGrid>

                      <div className="site_main_address">
                        <BlockStack gap={'200'}>
                          <Text as="h3" variant="headingMd">
                            Địa chỉ doanh nghiệp
                          </Text>

                          <TextField label="Dòng địa chỉ số 1" autoComplete="off" {...fields.site_main_address_1} />
                          <TextField label="Dòng địa chỉ số 2" autoComplete="off" {...fields.site_main_address_2} />
                          <FormLayout>
                            <FormLayout.Group condensed>
                              <TextField label="Quận/ Huyện" autoComplete="off" {...fields.site_main_address_district} />
                              <TextField label="Tỉnh/thành phố" autoComplete="off" {...fields.site_main_address_city} />
                            </FormLayout.Group>
                          </FormLayout>

                          <Text as="p">Những thông tin này sẽ được ghi vào trong Email và một số template khác ví dụ như invoice ...</Text>
                        </BlockStack>
                      </div>
                    </BlockStack>
                  </Card>
      }, {
        term: 'Thông báo từ hệ thống',
        description: <Card>
                    <Grid>
                      <Grid.Cell columnSpan={{
              xs: 6,
              sm: 3,
              md: 3,
              lg: 6,
              xl: 6
            }}>
                        <QuickSearchEmailTemplate title="Chọn mẫu email mặc định" current_setting={entities?.email_default_template} onClose={email_template_id => useFields.email_default_template.onChange(email_template_id)} />
                      </Grid.Cell>

                      <Grid.Cell columnSpan={{
              xs: 6,
              sm: 3,
              md: 3,
              lg: 6,
              xl: 6
            }}>
                        <QuickSearchEmailSetting title="Chọn tài khoản email mặc định" current_setting={entities?.email_default_setting} onClose={email_setting_id => useFields.email_default_setting.onChange(email_setting_id)} />
                      </Grid.Cell>
                    </Grid>
                    <br />
                    <TextField label="Email của Admin" placeholder="admin@you.com" helpText={`Là email nhận các thông báo tự động từ hệ thống. Email có thể chứa các thông tin quan trọng.`} autoComplete="off" connectedRight={<Button loading={sendingEmailTesting} onClick={() => SendTestEmail()} size="large" variant="primary">
                          Gửi thử
                        </Button>} {...fields.admin_email} />
                  </Card>
      }, {
        term: 'Cài đặt nâng cao',
        description: <Card>
                    <BlockStack gap="400">
                      <Text as="h3">Dọn dẹp phiên làm việc</Text>
                      <Button tone="critical" loading={false} onClick={clearAllSession}>
                        Xoá toàn bộ phiên làm việc
                      </Button>
                      <Text tone="subdued" as="p">
                        Khi dọn dẹp phiên làm việc, mọi phiên làm việc hết hạn trong cơ sở dữ liệu sẽ được xóa bỏ một cách an toàn.
                      </Text>
                    </BlockStack>
                  </Card>
      }]} />

          <Card padding={'0'}></Card>

          {dirty && <ContextualSaveBar message="Thay đổi chưa được lưu" saveAction={{
        onAction: () => submit(),
        loading: updating
      }} discardAction={{
        onAction: () => resetForm()
      }} />}
        </Page>}
      <br />
      <br />
    </>;
}