import { useGetStock } from 'queries/stock.query';
import { useNavigate, useParams } from 'react-router-dom';
import { Badge, BlockStack, Card, EmptyState, Grid, Page, Text } from '@shopify/polaris';
import SkeletonPageLoading2 from 'components/skeletonPageLoading2';
import { Helmet } from 'react-helmet-async';
import { lazy, Suspense, useCallback, useEffect, useState } from 'react';
import { EditIcon } from '@shopify/polaris-icons';
import StockCreateModal from './stock.create.update';
import helpers from 'helpers/index';
const StockLogisticSetting = lazy(() => import('./components/stock_logistic_setting'));
export default function StockDetail() {
  let {
    stock_id
  } = useParams();
  let history = useNavigate();
  const {
    error,
    data: entity,
    isLoading
  } = useGetStock(stock_id);
  const [showModal, setShowModal] = useState(false);
  const [stockAddressCity, setStockAddressCity] = useState('');
  const [stockAddressDistrict, setStockAddressDistrict] = useState('');
  const [stockAddressWard, setStockAddressWard] = useState('');
  const fetchAddress = useCallback(async () => {
    if (!entity) return;
    let diachiTinhData = await helpers.getDiaChinh(entity?.stock_address_city);
    setStockAddressCity(diachiTinhData?.name_with_type);
    let diachiQuanData = await helpers.getDiaChinh(entity?.stock_address_district);
    setStockAddressDistrict(diachiQuanData?.name_with_type);
    let diachiPhuongData = await helpers.getDiaChinh(entity?.stock_address_ward);
    setStockAddressWard(diachiPhuongData?.name_with_type);
  }, [entity]);
  useEffect(() => {
    fetchAddress();
  }, [entity]);
  return <>
      <Helmet>
        <title>Thông tin kho hàng</title>
      </Helmet>
      {error ? <EmptyState heading="Có gì đó không đúng!" action={{
      content: 'Quay lại danh sách',
      onAction: () => history('/stock')
    }} image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png">
          <p>Không tìm thấy dữ liệu bạn yêu cầu.</p>
        </EmptyState> : isLoading ? <SkeletonPageLoading2 /> : <>
          <Page subtitle={entity?.stock_code} title={`Kho hàng ` + entity?.stock_name} backAction={{
        content: 'Quay lại danh sách',
        onAction: () => history('/stock')
      }} primaryAction={{
        content: 'Chỉnh sửa kho',
        icon: EditIcon,
        onAction: () => setShowModal(true)
      }}>
            <Grid columns={{
          sm: 3
        }}>
              <Grid.Cell columnSpan={{
            xs: 6,
            sm: 4,
            md: 4,
            lg: 8,
            xl: 8
          }}>
                {/* Left column content */}
                <Suspense fallback={<>Đang tải...</>}>
                  <StockLogisticSetting stock_id={stock_id} />
                </Suspense>
              </Grid.Cell>
              <Grid.Cell columnSpan={{
            xs: 6,
            sm: 2,
            md: 2,
            lg: 4,
            xl: 4
          }}>
                <Card>
                  <BlockStack gap={'400'}>
                    <Text as="h3" variant="headingMd">
                      {entity?.is_default && <Badge tone="magic">Mặc định</Badge>} {` `}
                      {entity?.stock_name} ({entity?.stock_code})
                    </Text>

                    <Text as="h3" variant="headingMd">
                      Địa chỉ
                    </Text>

                    <p>
                      {entity?.stock_address}, {stockAddressWard}, {stockAddressDistrict}, {stockAddressCity}
                    </p>
                    <Text as="h3" variant="headingMd">
                      Thông tin người quản lý
                    </Text>

                    <p>
                      {entity?.manager} <br /> {entity?.hotline}
                    </p>

                    <Text as="h3" variant="headingMd">
                      Profile thanh toán
                    </Text>
                    {entity?.payment_profile_data ? <div>
                        <Text as="h3" fontWeight="bold" tone="caution">
                          <span>{entity?.payment_profile_data.payment_name}</span>
                        </Text>
                        <Text as="p" tone="subdued" variant="bodySm">
                          {entity?.payment_profile_data.payment_description}
                        </Text>
                      </div> : <p>Chưa cập nhật thông tin thanh toán</p>}
                  </BlockStack>
                </Card>
              </Grid.Cell>
            </Grid>
          </Page>
          <StockCreateModal onClose={() => {
        setShowModal(false);
      }} current_stock_id={stock_id} show={showModal} />
        </>}
    </>;
}