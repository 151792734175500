import { useState, useEffect } from 'react';
import { BlockStack, Text } from '@shopify/polaris';
interface TimeoutProps {
  duration: number; // Thời gian đếm ngược (tính bằng giây)
  children: React.ReactNode;
  timeoutTitle?: string;
  activeTitle?: string;
}
const CountdownTime: React.FC<TimeoutProps> = ({
  duration,
  children,
  activeTitle = 'Thời gian thanh toán',
  timeoutTitle = 'Hết thời gian thanh toán!'
}) => {
  const [timeLeft, setTimeLeft] = useState(duration);
  const [expired, setExpired] = useState(false);
  useEffect(() => {
    if (timeLeft <= 0) {
      setExpired(true);
      return;
    }
    const timer = setInterval(() => {
      setTimeLeft(prev => prev - 1);
    }, 1000);
    return () => clearInterval(timer);
  }, [timeLeft]);

  // Chuyển đổi giây sang giờ, phút, giây
  const formatTime = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor(seconds % 3600 / 60);
    const secs = seconds % 60;
    return [hours > 0 ? `${hours}h` : '', minutes > 0 ? `${minutes}m` : hours > 0 ? '0m' : '', `${secs}s`].filter(Boolean).join(' ');
  };
  return <BlockStack gap={'400'}>
      {expired ? <Text as="p">{timeoutTitle}</Text> : <>
          <Text as="p">
            {activeTitle}:{' '}
            <Text as="span" tone="subdued">
              {formatTime(timeLeft)}
            </Text>
          </Text>
          {children}
        </>}
    </BlockStack>;
};
export default CountdownTime;