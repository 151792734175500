import { Banner, BlockStack, Box, Button, Card, Checkbox, ChoiceList, ContextualSaveBar, Divider, EmptyState, Form, FormLayout, Icon, InlineGrid, InlineStack, Layout, Page, RadioButton, Select, Tag, Text, TextField, Toast, Tooltip } from '@shopify/polaris';
import { DeleteIcon, LinkIcon, PlusIcon, QuestionCircleIcon, StatusActiveIcon } from '@shopify/polaris-icons';
import { lengthLessThan, lengthMoreThan, notEmpty, useField, useForm } from '@shopify/react-form';
import SkeletonLoading from 'components/skeletonPageLoading';
import { useCreateProduct, useGetProduct, useUpdateProduct } from 'queries/product.query';
import { Suspense, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import helpers from '../../helpers';
import Capacity from 'components/Capacity';
import { FormPrompt } from 'components/FormPrompt';
import TextFieldRichText from 'components/TextFieldRichText';
import DialogConfirm from 'components/dialogConfirm';
import QuickUploadImage from 'components/oneclick-upload-image';
import QuickSearchPost from 'components/quickSearchPost';
import QuickSearchProduct2 from 'components/quickSearchProduct2';
import QuickSearchProductBrand from 'components/quickSearchProductBrand';
import QuickSearchProductCategory2 from 'components/quickSearchProductCategory';
import QuickSearchVoucher from 'components/quickSearchVoucher';
import QuickSearchWallet from 'components/quickSearchWallet';
import { useNotification } from 'context/NotificationContext';
import { useSettings } from 'context/SettingsContext';
import { useUserRoleContext } from 'context/UserRoleContext';
import QuickSearchProductCollection from 'entities/product/product_collection/quickSearchProductCollection';
import { TypedProductCommission, TypedProductCommissionAdvance } from 'interface/product.model';
import __ from 'languages/index';
import { DEEPLINK_SCREEN_NAMES, SCREEN_PATHS, SUGGEST_NOTIFICATION_DATA_KEY } from 'queries/notification.query';
import { Helmet } from 'react-helmet-async';
import ProductGallery from './ProductGallery';
import ProductVariants from './product_variant/list';
export default function ProductEdit() {
  let {
    product_id: current_product_id
  } = useParams();
  if (current_product_id === 'new') current_product_id = null;
  const {
    addNotification
  } = useNotification();
  const {
    settings
  } = useSettings();
  const {
    mutateAsync: getEntity,
    data: entity,
    isPending: loading
  } = useGetProduct();
  const {
    mutateAsync: updateEntity,
    isPending: updating,
    isSuccess: updateSuccess
  } = useUpdateProduct();
  const {
    mutateAsync: createEntity,
    isPending: creating
  } = useCreateProduct();
  const [selectedScreen, setSelectedScreen] = useState<any>(DEEPLINK_SCREEN_NAMES[0]);
  const [selectedParam, setSelectedParam] = useState<any>();
  const [internalNotification, setInternalNotification] = useState('');
  /** Lấy user role ra ... */
  const {
    allRoleSelection: allRoles
  } = useUserRoleContext();
  /** Hết code lấy user role ra ... */

  useEffect(() => {
    if (updateSuccess) {
      if (current_product_id) getEntity(current_product_id); // reload data ...
      addNotification('info', __('update_successfully'));
    }
  }, [updateSuccess, current_product_id]);
  const history = useNavigate();
  useEffect(() => {
    if (current_product_id) getEntity(current_product_id);
  }, [current_product_id]);
  const useFields = {
    product_thumbnail: useField<string>({
      value: entity?.product_thumbnail ?? '',
      validates: []
    }),
    product_thumbnail_cache: useField<string>({
      value: entity?.product_thumbnail_to_media ? entity.product_thumbnail_to_media?.media_thumbnail ? entity.product_thumbnail_to_media?.media_thumbnail['512x512'] : entity.product_thumbnail_to_media?.media_url : undefined,
      validates: []
    }),
    product_name: useField<string>({
      value: entity?.product_name ?? '',
      validates: [lengthLessThan(250, 'Tựa đề không được quá 250 ký tự.'), lengthMoreThan(2, 'Tựa đề phải dài hơn 2 ký tự.'), inputValue => {
        if (inputValue.length < 2) {
          return 'Tựa đề quá ngắn hoặc đang rỗng.';
        }
      }]
    }),
    product_slug: useField<string>({
      value: entity?.product_slug ?? '',
      validates: [inputVal => {
        if (helpers.isUTF8(inputVal)) {
          return 'Slug không được chứa ký tự đặc biệt';
        }
      }]
    }),
    product_excerpt: useField<string>({
      value: entity?.product_excerpt ?? '',
      validates: [lengthLessThan(250, 'Đoạn mô tả ngắn không được dài hơn 250 ký tự.')]
    }),
    product_description: useField<string>({
      value: entity?.product_description ?? '',
      validates: [lengthLessThan(65000, 'Nội dung mô tả không được dài hơn 65000 ký tự.')]
    }),
    product_price: useField<string>({
      value: entity?.product_price ? String(entity?.product_price) : '0',
      validates: [inputValue => {
        if (inputValue && helpers.parseNumeric(inputValue) < 1) {
          return 'product_price phải là một số!';
        }
      }]
    }),
    product_original_price: useField<string>({
      value: String(entity?.product_original_price ?? '0'),
      validates: [inputValue => {
        if (inputValue && helpers.parseNumeric(inputValue) < 0) {
          return 'product_original_price phải là một số!';
        }
      }]
    }),
    product_size_length: useField<string>({
      value: entity?.product_size_length ? String(entity?.product_size_length) : '',
      validates: [inputValue => {
        if (inputValue && Number.isNaN(Number(inputValue))) {
          return 'product_size_length phải là một số!';
        }
      }]
    }),
    product_size_width: useField<string>({
      value: entity?.product_size_width ? String(entity?.product_size_width) : '',
      validates: [inputValue => {
        if (inputValue && Number.isNaN(Number(inputValue))) {
          return 'product_size_width phải là một số!';
        }
      }]
    }),
    product_size_height: useField<string>({
      value: entity?.product_size_height ? String(entity?.product_size_height) : '',
      validates: [inputValue => {
        if (inputValue && Number.isNaN(Number(inputValue))) {
          return 'product_size_height phải là một số!';
        }
      }]
    }),
    product_size_weight: useField<string>({
      value: entity?.product_size_weight ? String(entity?.product_size_weight) : '',
      validates: [inputValue => {
        if (inputValue && Number.isNaN(Number(inputValue))) {
          return 'product_size_weight phải là một số!';
        }
      }]
    }),
    product_sku: useField<string>({
      value: entity?.product_sku ?? '',
      validates: [lengthLessThan(50, 'SKU không được dài hơn 40 ký tự'), inputValue => {
        if (helpers.isUTF8(inputValue)) {
          return 'Mã SKU không được sử dụng mã UTF-8.';
        }
      }]
    }),
    product_barcode: useField<string>({
      value: entity?.product_barcode ?? '',
      validates: [lengthLessThan(48, 'Barcode không được nhiều hơn 48 ký tự.'), inputValue => {
        if (helpers.isUTF8(inputValue)) {
          return 'Barcode không được sử dụng mã UTF-8.';
        }
      }]
    }),
    product_tags: useField<string>({
      value: entity?.product_tags ?? '',
      validates: [lengthLessThan(255, 'Tags không được nhiều hơn 255 ký tự.')]
    }),
    product_type: useField<string>({
      value: entity?.product_type ?? 'normal',
      validates: []
    }),
    product_status: useField<string>({
      value: entity ? String(entity.product_status) : '0',
      validates: []
    }),
    product_category: useField<string[]>({
      value: entity ? entity?.product_to_category.map(el => el.category_id) : [],
      validates: [notEmpty('Chuyên mục không được để trống'), inputVal => {
        if (helpers.isEmpty(inputVal)) return 'Chuyên mục yêu cầu phải được điền.';
      }]
    }),
    product_brand: useField<string>({
      value: entity ? entity?.product_to_brand?.product_brand?.brand_id : '',
      validates: []
    }),
    product_has_variants: useField<number>({
      value: entity?.product_has_variants || 0,
      validates: []
    }),
    product_badge: useField<string>({
      value: entity?.product_badge ?? '',
      validates: []
    }),
    is_sensitive: useField<number>({
      value: entity?.is_sensitive || 0,
      validates: []
    }),
    product_measurement_unit: useField<string>({
      value: entity?.product_measurement_unit ?? '',
      validates: []
    }),
    product_group_income_value: useField<string>({
      value: String(entity?.product_group_income?.group_income_value ?? 0),
      validates: []
    }),
    product_has_commission: useField<string>({
      value: String(entity?.product_has_commission ?? -1),
      validates: []
    }),
    product_commission: useField<TypedProductCommission[]>({
      value: helpers.isEmpty(entity?.product_commission || []) ? [{
        commission_level: 1,
        commission_value: 0,
        commission_method: 'percent'
      }] : entity?.product_commission,
      validates: []
    }),
    product_commission_advance: useField<TypedProductCommissionAdvance[]>({
      value: entity?.product_commission_advance || [],
      validates: []
    }),
    product_has_rebate: useField<string>({
      value: String(entity?.product_has_rebate ?? -1),
      validates: []
    }),
    product_rebate: useField<{
      rebate_value: number;
      rebate_method: string;
      user_role: string;
    }[]>({
      value: helpers.isEmpty(entity?.product_rebate || []) ? [{
        rebate_value: 1,
        rebate_method: 'percent',
        user_role: 'visitor'
      }] : entity?.product_rebate?.map(({
        rebate_value,
        rebate_method,
        user_role
      }) => {
        return {
          rebate_value,
          rebate_method,
          user_role
        };
      }),
      validates: []
    }),
    product_has_tiered_rebate: useField<string>({
      value: String(entity?.product_has_tiered_rebate ?? -1),
      validates: []
    }),
    product_tiered_rebate: useField<{
      rebate_value: number;
      rebate_method: string;
      user_role: string;
    }[]>({
      value: helpers.isEmpty(entity?.product_tiered_rebate || []) ? [{
        rebate_value: 0,
        rebate_method: 'percent',
        user_role: 'collaborator'
      }, {
        rebate_value: 0,
        rebate_method: 'percent',
        user_role: 'agency'
      }] : entity?.product_tiered_rebate?.map(({
        rebate_value,
        rebate_method,
        user_role
      }) => {
        return {
          rebate_value,
          rebate_method,
          user_role
        };
      }),
      validates: []
    }),
    _product_super_meta: useField<{
      meta_key: string;
      meta_value: string;
    }[]>({
      value: entity?.product_super_meta || [],
      validates: []
    })
  };

  /** Debug only */
  // Object.values(useFields).map((field, index) => {
  //   if (field.dirty) {
  //     console.info(`Field ${field.value} said it is modified and dirty.`);
  //   }
  // });

  const {
    product_commission,
    product_rebate,
    product_tiered_rebate,
    product_commission_advance
  } = useFields;
  const addNewRuleToProductCommissionAdvanceCallback = useCallback((commission_parent_index: number) => {
    let currentValue = [...useFields.product_commission_advance.value, ...[]];
    currentValue.push({
      commission_parent_index: commission_parent_index,
      user_role: 'customer',
      commission_value: 1
    });
    useFields.product_commission_advance.onChange(currentValue);
  }, [useFields.product_commission_advance]);
  const deleteRuleToProductCommissionAdvanceCallback = useCallback((commission_parent_index: number, index_id: number) => {
    let currentValue = [...useFields.product_commission_advance.value, ...[]];
    currentValue = currentValue.filter(e => e.commission_parent_index === commission_parent_index);
    currentValue = currentValue.filter((e, i) => i !== index_id);
    useFields.product_commission_advance.onChange(currentValue);
  }, [useFields.product_commission_advance]);
  const addNewRuleToProductCommissionCallback = useCallback(() => {
    let currentValue = [...useFields.product_commission.value, ...[]];
    if (currentValue.length > 2) return;
    currentValue.push({
      commission_level: currentValue.length + 1,
      commission_method: 'percent',
      commission_value: 1
    });
    useFields.product_commission.onChange(currentValue);
  }, [useFields.product_commission]);
  const deleteRuleToProductCommissionCallback = useCallback((index_id: number) => {
    let currentValue = [...useFields.product_commission.value, ...[]];
    currentValue = currentValue.filter((e, i) => i !== index_id);
    useFields.product_commission.onChange(currentValue);
  }, [useFields.product_commission]);
  const addNewRuleToProductRebateCallback = useCallback(() => {
    let currentValue = [...useFields.product_rebate.value, ...[]];
    currentValue.push({
      rebate_value: 1,
      rebate_method: 'percent',
      user_role: 'visitor'
    });
    useFields.product_rebate.onChange(currentValue);
  }, [useFields.product_rebate]);
  const deleteRuleToProductRebateCallback = useCallback((index_id: number) => {
    let currentValue = [...useFields.product_rebate.value, ...[]];
    currentValue = currentValue.filter((e, i) => i !== index_id);
    useFields.product_rebate.onChange(currentValue);
  }, [useFields.product_rebate]);
  useEffect(() => {
    // chế độ thêm mới, thì mình chỉnh product_slug
    if (!entity) useFields.product_slug.onChange(helpers.slugify(useFields.product_name.value));
  }, [useFields.product_name.value, entity]);

  /** check giá của hai cái trường giá và báo lỗi cho khách  */
  useEffect(() => {
    let price = helpers.parseNumeric(useFields.product_price.value);
    let original_price = helpers.parseNumeric(useFields.product_original_price.value);
    if (original_price > 0 && original_price < price) {
      useFields.product_original_price.setError('Giá gốc phải là số cao hơn giá bán.');
    }
  }, [useFields.product_price.value, useFields.product_original_price.value]);

  /** Sort lại trường này */
  const sortedTieredRebates = [...useFields.product_tiered_rebate.value, ...[]]?.sort((a, b) => {
    if (a.user_role < b.user_role) return 1;
    if (a.user_role > b.user_role) return -1;
    return 0;
  });
  const {
    fields,
    submit,
    submitting,
    dirty,
    reset: Userreset,
    submitErrors
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        if (!current_product_id) {
          // create new
          let afterUpdate = await createEntity({
            product_name: values.product_name,
            product_slug: values.product_slug,
            product_excerpt: values.product_excerpt,
            product_description: values.product_description,
            product_thumbnail: values.product_thumbnail || null,
            product_price: helpers.parseNumeric(values.product_price),
            product_original_price: helpers.parseNumeric(values.product_original_price),
            product_sku: values.product_sku,
            product_barcode: values.product_barcode,
            product_badge: values.product_badge,
            product_measurement_unit: values.product_measurement_unit,
            is_sensitive: values.is_sensitive,
            product_tags: values.product_tags,
            product_type: String(values.product_type),
            product_status: Number(values.product_status),
            product_size_length: Number(values.product_size_length),
            product_size_width: Number(values.product_size_width),
            product_size_height: Number(values.product_size_height),
            product_size_weight: Number(values.product_size_weight),
            product_category: values.product_category,
            product_has_variants: Number(values.product_has_variants),
            product_media: [],
            product_brand: values.product_brand,
            /** Doanh thu đội nhóm */
            product_group_income_value: helpers.parseNumeric(values.product_group_income_value),
            product_has_commission: Number(values.product_has_commission),
            product_has_rebate: Number(values.product_has_rebate),
            product_has_tiered_rebate: Number(values.product_has_tiered_rebate),
            commission: values.product_commission,
            commission_advance: values.product_commission_advance,
            rebate: values.product_rebate,
            tiered_rebate: sortedTieredRebates,
            product_super_meta: values._product_super_meta
          });
          history('/product/edit/' + afterUpdate?.data?.product_id);
        } else {
          await updateEntity({
            product_id: current_product_id,
            product_name: values.product_name,
            product_slug: values.product_slug,
            product_excerpt: values.product_excerpt,
            product_description: values.product_description,
            product_thumbnail: values.product_thumbnail || null,
            product_price: helpers.parseNumeric(values.product_price),
            product_original_price: helpers.parseNumeric(values.product_original_price),
            product_sku: values.product_sku,
            product_barcode: values.product_barcode,
            product_badge: values.product_badge,
            product_measurement_unit: values.product_measurement_unit,
            is_sensitive: values.is_sensitive,
            product_tags: values.product_tags,
            product_type: String(values.product_type),
            product_status: Number(values.product_status),
            product_size_length: Number(values.product_size_length),
            product_size_width: Number(values.product_size_width),
            product_size_height: Number(values.product_size_height),
            product_size_weight: Number(values.product_size_weight),
            product_has_variants: Number(values.product_has_variants),
            product_category: values.product_category,
            product_brand: values.product_brand || null,
            // <<<< nếu họ muốn remove thì sao

            /** Doanh thu đội nhóm */
            product_group_income_value: helpers.parseNumeric(values.product_group_income_value),
            product_has_commission: Number(values.product_has_commission),
            product_has_rebate: Number(values.product_has_rebate),
            product_has_tiered_rebate: Number(values.product_has_tiered_rebate),
            commission: values.product_commission,
            commission_advance: values.product_commission_advance,
            rebate: values.product_rebate,
            tiered_rebate: sortedTieredRebates,
            // super meta ...
            product_super_meta: values._product_super_meta
          });
        }
      } catch (e: any) {
        if (typeof e.params !== 'undefined' && e.params.field) {
          useFields[e.params.field]?.setError(e.message);
        }
      }
      return {
        status: 'success'
      };
    }
  });
  const errorBanner = submitErrors.length > 0 ? <>
        <Banner tone="warning">
          <p>Có một số trường nhập cần được sửa lại trước khi cập nhật:</p>
          <ul>
            {submitErrors.map(({
          message
        }, index) => {
          return <li key={`${message}${index}`}>{message}</li>;
        })}
          </ul>
        </Banner>
        <br />
      </> : null;
  const productTypeOption = useFields.product_type;
  const productStatusOption = useFields.product_status;
  const handUploadError = useCallback((err: any) => {
    addNotification('error', err?.message ?? 'unknown_error');
  }, []);
  const [showConfirmUnsavedForm, setShowConfirmUnsavedForm] = useState(false);
  const [productTagsValue, setProductTagsValue] = useState('');
  const removeProductTagCallback = useCallback((keywords: string) => {
    if (!useFields.product_tags.value) return;
    let OriginalKey = useFields.product_tags.value.split(',').filter(el => el !== keywords);
    useFields.product_tags.onChange(OriginalKey.join(','));
  }, [useFields.product_tags]);
  const renderNotificationParamField = useCallback(() => {
    if (!SCREEN_PATHS[selectedScreen].includes('{params}')) {
      return null;
    }
    switch (selectedScreen) {
      case 'NAVIGATION_LIST_PRODUCT_SCREEN_CATEGORY':
        return <QuickSearchProductCategory2 label="Chọn danh mục" current_category_id={[]} onClose={categories => setSelectedParam('category/' + categories?.pop())} allowMultiple={false} />;
      case 'NAVIGATION_DETAIL_POLICY_SCREEN':
        return <QuickSearchPost label="Chọn bài đăng/trang" onClose={posts => setSelectedParam('policy-detail/' + posts?.post_id)} />;
      case 'NAVIGATION_DETAIL_NEWS_SCREEN':
        return <QuickSearchPost label="Chọn tin tức" onClose={posts => setSelectedParam('news-detail/' + posts?.post_id)} />;
      case 'NAVIGATION_LIST_PRODUCT_SCREEN_COLLECTION':
        return <QuickSearchProductCollection label="Chọn bộ sưu tập" current_collection_id={[]} onClose={collections => setSelectedParam('collection/' + collections?.pop())} allowMultiple={false} />;
      case 'NAVIGATION_LIST_PRODUCT_SCREEN_BRAND':
        return <QuickSearchProductBrand label="Chọn thương hiệu" current_brand_id={[]} onClose={brands => setSelectedParam('brand/' + brands?.pop())} allowMultiple={false} />;
      case 'NAVIGATION_DETAIL_PRODUCT_SCREEN':
        return <QuickSearchProduct2 label="Chọn sản phẩm" current_product_id={[]} onClose={products => setSelectedParam('@' + products?.pop()?.product_id)} allowMultiple={false} />;
      case 'NAVIGATION_DETAIL_VOUCHER_SCREEN':
        return <QuickSearchVoucher title="Chọn voucher" onClose={voucher => setSelectedParam(voucher?.voucher_id)} />;
      default:
        return <TextField label="Thông số" value={selectedParam || ''} onChange={setSelectedParam} autoComplete="off" />;
    }
  }, [selectedScreen]);
  const toggleToastActive = useCallback(() => {
    setInternalNotification('');
  }, []);
  const toastMarkup = internalNotification ? <Toast content={internalNotification} onDismiss={toggleToastActive} duration={4500} /> : null;
  return <>
      <Helmet>
        <title>Chỉnh sửa sản phẩm</title>
      </Helmet>
      {toastMarkup}
      {dirty && <ContextualSaveBar message="Thay đổi của bạn chưa được lưu." saveAction={{
      onAction: submit,
      loading: updating,
      disabled: !dirty
    }} discardAction={{
      content: 'Hoàn tác',
      onAction: Userreset
    }} />}

      {loading ? <SkeletonLoading /> : <Page backAction={{
      content: 'Products',
      onAction: () => dirty ? setShowConfirmUnsavedForm(true) : history(-1)
    }} title={entity ? helpers.getTrimContent(entity.product_name, 50) : 'Thêm mới sản phẩm'} subtitle={entity?.product_excerpt || ''} compactTitle>
          {errorBanner}
          <Layout>
            <Layout.Section>
              <Form onSubmit={() => {}}>
                <FormLayout>
                  <Card>
                    <Text as="h2" variant="headingSm">
                      Thông tin chung
                    </Text>
                    <br />

                    <InlineGrid gap="200" columns={{
                  xs: '',
                  md: ['oneThird', 'twoThirds']
                }}>
                      <QuickUploadImage height={180} width={180} placeholder={helpers.getMediaLink(useFields.product_thumbnail_cache.value, 'https://placehold.co/512x512')} onSuccess={res => {
                    useFields.product_thumbnail.onChange(res.media_id);
                    useFields.product_thumbnail_cache.onChange(res.media_url);
                  }} onError={handUploadError} />
                      <div>
                        <BlockStack gap={'500'}>
                          <TextField autoComplete="off" autoFocus label="Tên sản phẩm" {...fields.product_name} />
                          <TextField autoComplete="off" label="Đường dẫn tĩnh" {...fields.product_slug} />

                          <TextField autoComplete="off" maxLength={250} label="Trích dẫn / mô tả ngắn" {...fields.product_excerpt} showCharacterCount={true} multiline={2} />
                        </BlockStack>
                      </div>
                    </InlineGrid>
                    <br />
                    <Banner tone="info">
                      <Text as="p" variant="headingMd">
                        Ghi chú: Bạn có thể chọn màn hình và thông số phù hợp để tạo liên kết động cho nội dung này, gắn liên kết vào các vị trí mà
                        bạn muốn người dùng ấn vào sẽ mở màn hình tương ứng. Bạn có thể để liên kết vào ảnh.
                      </Text>
                      <FormLayout>
                        <FormLayout.Group>
                          <Select options={DEEPLINK_SCREEN_NAMES.map(screen => {
                        return {
                          label: `${SUGGEST_NOTIFICATION_DATA_KEY.includes(screen) ? '⭐ ' : ''}${__('MOBILE_' + screen)}`,
                          value: screen
                        };
                      })} label="Màn hình" value={selectedScreen} onChange={v => {
                        setSelectedScreen(v);
                        setSelectedParam(undefined);
                      }} />
                          {renderNotificationParamField()}
                        </FormLayout.Group>
                      </FormLayout>

                      <BlockStack gap="500">
                        <TextField autoComplete={'off'} label=" " value={`${process.env.REACT_APP_DEEPLINK_URL}://${SCREEN_PATHS?.[selectedScreen].replace('{params}', selectedParam || '')}`} readOnly prefix={<Icon source={LinkIcon} />} />
                        <Button onClick={() => {
                      if (SCREEN_PATHS[selectedScreen]?.includes('{params}') && !selectedParam) {
                        setInternalNotification('Vui lòng nhập thông số hoặc chọn sản phẩm, danh mục,bộ sưu tập,v.v... tương ứng trước khi sao chép liên kết!');
                        return;
                      }
                      navigator.clipboard.writeText(`${process.env.REACT_APP_DEEPLINK_URL}://${SCREEN_PATHS?.[selectedScreen]?.replace('{params}', selectedParam || '')}`);
                      setInternalNotification('Liên kết đã được sao chép!');
                    }}>
                          Sao chép liên kết động
                        </Button>
                      </BlockStack>
                    </Banner>
                    <TextFieldRichText label="Nội dung"
                // maxLength={65000}
                initialData={entity?.product_description} onCallback={e => fields.product_description.onChange(e)} />
                  </Card>

                  <Card>
                    <Text as="h2" variant="headingSm">
                      Thư viện đa phương tiện
                    </Text>
                    <br />
                    {!current_product_id ? <EmptyState heading="Lưu bản nháp trước, sau đó sẽ thêm được các ảnh và video..." action={{
                  content: 'Lưu nháp ngay',
                  icon: StatusActiveIcon,
                  onAction: () => submit(),
                  loading: creating
                }} image={''}></EmptyState> : <ProductGallery product_id={current_product_id} />}
                  </Card>

                  <BlockStack>
                    <RadioButton label="Sản phẩm không có phân loại" checked={useFields.product_has_variants.value === 0} id="no_variant" name="product_has_variants" onChange={() => useFields.product_has_variants.onChange(0)} />
                    <RadioButton label="Sản phẩm nhiều phân loại" helpText="Bạn sẽ cài đặt giá và chiết khấu cho từng phân loại." id="has_variant" name="product_has_variants" checked={useFields.product_has_variants.value === 1} onChange={() => useFields.product_has_variants.onChange(1)} />
                  </BlockStack>
                  <Card>
                    {useFields.product_has_variants.value === 0 && <>
                        <Text as="h2" variant="headingSm">
                          Giá cả
                        </Text>
                        <br />
                        <BlockStack gap="400">
                          <FormLayout.Group>
                            <TextField autoComplete="off" label="Giá bán" value={helpers.formatNumberWithCommas(useFields.product_price.value)} onChange={e => useFields.product_price.onChange(e)} error={useFields.product_price.error} />
                            <TextField autoComplete="off" label="Giá gốc" suffix={<Tooltip content={<>
                                      Nhập giá trị cao hơn để hiển thị mức giá giảm.
                                      <br />
                                      Ứng dụng và web sẽ hiển thị giá này có <del>gạch giữa</del>.
                                    </>}>
                                  <Button icon={QuestionCircleIcon} variant="plain"></Button>
                                </Tooltip>} value={helpers.formatNumberWithCommas(useFields.product_original_price.value)} onChange={e => useFields.product_original_price.onChange(e)} error={useFields.product_original_price.error} />
                          </FormLayout.Group>
                        </BlockStack>

                        <Capacity current_user_can={'edit_products_commission'} extra_condition={settings?.group_income_calculator_turnon === '1'}>
                          <>
                            <br />
                            <Divider />
                            <br />
                            <BlockStack gap={'400'}>
                              <Text as="h3" variant="headingMd">
                                Tính vào doanh thu đội nhóm
                              </Text>
                              <FormLayout.Group>
                                <TextField autoComplete="off" label="Giá trị" value={helpers.formatNumberWithCommas(useFields.product_group_income_value.value)} onChange={e => useFields.product_group_income_value.onChange(e)} />
                                <QuickSearchWallet disabled title="Ví nhận/ đơn vị tính" current_wallet_id={settings?.group_income_calculator_wallet_id} />
                              </FormLayout.Group>
                            </BlockStack>
                          </>
                        </Capacity>

                        {/** Chỉ admin mới được quyền chỉnh sửa hoa hồng nha ... */}
                        <Capacity current_user_can={'edit_products_commission'}>
                          <br />
                          <Divider />
                          <br />
                          <BlockStack gap="400">
                            <ChoiceList title="Hoa hồng (commission)" choices={[{
                        label: 'Tuân theo chính sách chung',
                        value: '-1'
                      }, {
                        label: 'Không có hoa hồng',
                        value: '0'
                      }, {
                        label: 'Tùy chỉnh',
                        value: '1'
                      }]} selected={[useFields.product_has_commission.value]} onChange={a => useFields.product_has_commission.onChange(a[0])} />

                            {useFields.product_has_commission.value === '1' && <BlockStack gap="400">
                                {product_commission.value.map((commission, index) => {
                          return <div key={'index_product_commission_' + index}>
                                      <TextField autoComplete="off" label={`Hoa hồng Level ${index + 1}`} labelAction={{
                              content: __('commission_method_label_' + commission.commission_method),
                              onAction: () => {
                                product_commission.onChange(product_commission.value.map((item, i) => i === index ? {
                                  ...item,
                                  commission_method: commission.commission_method === 'percent' ? 'fixed_amount' : 'percent'
                                } : item));
                              }
                            }} value={helpers.formatNumberWithCommas(commission.commission_value.toString())} onChange={value => product_commission.onChange(product_commission.value.map((item, i) => i === index ? {
                              ...item,
                              commission_value: helpers.parseNumeric(value)
                            } : item))} suffix={commission.commission_method === 'percent' ? '%' : 'fixed'} connectedLeft={<Button onClick={() => deleteRuleToProductCommissionCallback(index)} variant="plain" icon={DeleteIcon}></Button>} />
                                      <Box paddingInlineStart={'600'} paddingBlock={'200'}>
                                        <BlockStack gap={'400'}>
                                          {useFields.product_commission_advance.value.map((commission_advance, index2) => {
                                  if (commission_advance.commission_parent_index !== index) return undefined;
                                  return <TextField size="slim" label={`Nếu người nhận là *** thì nhận thêm (${helpers.formatNumberWithCommas(commission.commission_value.toString())} + x) giá trị`} connectedLeft={<Select label="" labelHidden options={allRoles} onChange={value => product_commission_advance.onChange(product_commission_advance.value.map((item, i) => i === index2 ? {
                                    ...item,
                                    user_role: value
                                  } : item))} value={commission_advance.user_role.toString()} />} labelAction={{
                                    content: 'Xoá',
                                    onAction: () => deleteRuleToProductCommissionAdvanceCallback(index, index2)
                                  }} autoComplete="off" value={helpers.formatNumberWithCommas(commission_advance.commission_value.toString())} onChange={value => product_commission_advance.onChange(product_commission_advance.value.map((item, i) => i === index2 ? {
                                    ...item,
                                    commission_value: helpers.parseNumeric(value)
                                  } : item))} placeholder="Giá trị" suffix={commission.commission_method === 'percent' ? '%' : 'fixed'} />;
                                })}

                                          <div>
                                            <Button variant="plain" tone="success" onClick={() => addNewRuleToProductCommissionAdvanceCallback(index)} icon={PlusIcon}>
                                              Thêm điều kiện nâng cao
                                            </Button>
                                          </div>
                                        </BlockStack>
                                      </Box>
                                    </div>;
                        })}
                                <Button icon={PlusIcon} onClick={() => addNewRuleToProductCommissionCallback()} variant="primary">
                                  Thêm quy tắc
                                </Button>
                                <Text as="p" tone="subdued">
                                  Hoa hồng trong chương trình affiliate được thanh toán SAU KHI đơn hàng hoàn thành đối soát. ĐÂY KHÔNG PHẢI LÀ CHIẾT
                                  KHẤU.
                                </Text>
                              </BlockStack>}

                            <Divider />

                            <ChoiceList title="Chiết khấu (rebate)" choices={[{
                        label: 'Tuân theo chính sách chung',
                        value: '-1'
                      }, {
                        label: 'Không có chiết khấu',
                        value: '0'
                      }, {
                        label: 'Tùy chỉnh',
                        value: '1'
                      }]} selected={[useFields.product_has_rebate.value]} onChange={a => useFields.product_has_rebate.onChange(a[0])} />

                            {useFields.product_has_rebate.value === '1' && <BlockStack gap="400">
                                {product_rebate.value.map((rebate, index) => {
                          return <InlineGrid columns={{
                            xs: 1,
                            md: 2
                          }} gap="400" key={'index_product_rebate_' + index}>
                                      <TextField autoComplete="off" label={`Giá trị chiết khấu ${index + 1}`} labelAction={{
                              content: __('commission_method_label_' + rebate.rebate_method),
                              onAction: () => {
                                product_rebate.onChange(product_rebate.value.map((item, i) => i === index ? {
                                  ...item,
                                  rebate_method: rebate.rebate_method === 'percent' ? 'fixed_amount' : 'percent'
                                } : item));
                              }
                            }} value={helpers.formatNumberWithCommas(rebate.rebate_value.toString())} onChange={value => product_rebate.onChange(product_rebate.value.map((item, i) => i === index ? {
                              ...item,
                              rebate_value: helpers.parseNumeric(value)
                            } : item))} suffix={rebate.rebate_method === 'percent' ? '%' : 'fixed'} connectedLeft={<Button onClick={() => deleteRuleToProductRebateCallback(index)} variant="plain" icon={DeleteIcon}></Button>} />

                                      <Select label={'Cho nhóm người dùng'} options={allRoles} onChange={value => product_rebate.onChange(product_rebate.value.map((item, i) => i === index ? {
                              ...item,
                              user_role: String(value)
                            } : item))} value={rebate.user_role.toString()} />
                                    </InlineGrid>;
                        })}
                                <Button icon={PlusIcon} onClick={() => addNewRuleToProductRebateCallback()} variant="primary">
                                  Thêm quy tắc
                                </Button>
                                <Text as="p" tone="subdued">
                                  Chiết khấu là số tiền cứng sau khi bán một sản phẩm. Số tiền này được hoàn lại vào tài khoản của người mua sau khi
                                  đơn hàng được đối soát.
                                </Text>
                              </BlockStack>}

                            <Divider />

                            <ChoiceList title="Chiết khấu phân tầng (Tiered rebate)" choices={[{
                        label: 'Tuân theo chính sách chung',
                        value: '-1'
                      }, {
                        label: 'Không có chiết khấu',
                        value: '0'
                      }, {
                        label: 'Tùy chỉnh',
                        value: '1'
                      }]} selected={[useFields.product_has_tiered_rebate.value]} onChange={a => useFields.product_has_tiered_rebate.onChange(a[0])} />

                            {useFields.product_has_tiered_rebate.value === '1' && <BlockStack gap="400">
                                {product_tiered_rebate.value.map((tiered_rebate, index) => {
                          return <TextField autoComplete="off" label={`Chiết khấu cho ${__(`user_role_` + tiered_rebate.user_role)}`} labelAction={{
                            content: __('commission_method_label_' + tiered_rebate.rebate_method),
                            onAction: () => {
                              product_tiered_rebate.onChange(product_tiered_rebate.value.map((item, i) => i === index ? {
                                ...item,
                                rebate_method: tiered_rebate.rebate_method === 'percent' ? 'fixed_amount' : 'percent'
                              } : item));
                            }
                          }} value={helpers.formatNumberWithCommas(tiered_rebate.rebate_value.toString())} onChange={value => product_tiered_rebate.onChange(product_tiered_rebate.value.map((item, i) => i === index ? {
                            ...item,
                            rebate_value: helpers.parseNumeric(value)
                          } : item))} suffix={tiered_rebate.rebate_method === 'percent' ? '%' : 'fixed'} />;
                        })}

                                <Text as="p" tone="subdued">
                                  Chiết khấu phân tầng là CHIẾT KHẤU và chia theo tầng, ở tầng thấp hơn khi mua hàng, các tầng trên có liên quan sẽ
                                  nhận được giá trị tương ứng.
                                </Text>
                                <Text as="p" tone="subdued">
                                  Chiết khấu này cũng sẽ được chia ngay sau khi đơn hàng giao thành công và được đánh dấu là "Đã đối soát".
                                </Text>
                              </BlockStack>}
                          </BlockStack>
                        </Capacity>
                      </>}
                    {useFields.product_has_variants.value === 1 && <BlockStack gap="400">
                        <Text as="h2" variant="headingSm">
                          Giá và hoa hồng của các phân loại
                        </Text>
                        <br />

                        <ProductVariants current_product_id={current_product_id} />
                        {entity?.product_price_range && <Text as="p">Giá từ {entity?.product_price_range} đ</Text>}
                      </BlockStack>}
                  </Card>

                  <Card>
                    <Text as="h2" variant="headingSm">
                      Kho vận
                    </Text>
                    <br />

                    <InlineGrid columns={{
                  xs: 1,
                  md: 2
                }} gap="400">
                      <TextField autoComplete="off" suffix={<Tooltip content={<>Đây không phải là số lượng trong kho. Đây là mã quản lý kho, hãy giữ nó đơn giản.</>}>
                            <Button icon={QuestionCircleIcon} variant="plain"></Button>
                          </Tooltip>} label="SKU (Đơn vị lưu kho)" {...fields.product_sku} />
                      <TextField autoComplete="off" maxLength={48} suffix={<Tooltip content="Từ 8 tới tối đa 48 chữ số cho mã vạch.">
                            <Button icon={QuestionCircleIcon} variant="plain"></Button>
                          </Tooltip>} label="Mã vạch (ISBN, UPC, GTIN, v.v.)" {...fields.product_barcode} />
                      <TextField autoComplete="off" maxLength={20} suffix={<Tooltip content="Ví dụ: Chiếc, Cái, Lọ, Bó, Hộp">
                            <Button icon={QuestionCircleIcon} variant="plain"></Button>
                          </Tooltip>} label="Đơn vị lưu kho" {...fields.product_measurement_unit} />
                    </InlineGrid>

                    <br />
                    <ChoiceList title=" " choices={[{
                  label: 'Đây là phẩm dịch vụ',
                  value: 'service',
                  helpText: 'Các sản phẩm dịch vụ không có số lượng hàng.'
                }, {
                  label: 'Đây là sản phẩm vật lý',
                  value: 'normal',
                  helpText: 'Sản phẩm vật lý có số lượng, có kích thước đóng gói và phí vận chuyển.'
                }]} selected={[productTypeOption.value]} onChange={([value]) => productTypeOption.onChange(value)} />
                    <br />

                    {productTypeOption.value === 'normal' && <>
                        <Divider />
                        <br />
                        <br />
                        <Text as="h4" variant="headingSm">
                          Kích thước và khối lượng
                        </Text>
                        <InlineGrid gap={'300'} columns={{
                    xs: 1,
                    sm: 1,
                    md: 3,
                    lg: 3,
                    xl: 3
                  }}>
                          <TextField label="Dài" {...fields.product_size_length} placeholder="cm" type="integer" autoComplete="off" />
                          <TextField label="Rộng" {...fields.product_size_width} placeholder="cm" type="integer" autoComplete="off" />
                          <TextField label="Cao" {...fields.product_size_height} placeholder="cm" type="integer" autoComplete="off" />
                        </InlineGrid>
                        <br />
                        <Text as="p" tone="subdued">
                          Đơn vị tính của Dài x Rộng x Cao là Centimet.
                        </Text>
                        <br />
                        <InlineGrid gap={'300'} columns={{
                    xs: 1,
                    sm: 1,
                    md: 2,
                    lg: 2,
                    xl: 2
                  }}>
                          <TextField autoComplete="off" label="Trọng lượng vận chuyển" {...fields.product_size_weight} />
                          <Select disabled options={['oz', 'g', 'kg', 'lb']} value={'kg'} onChange={null} label={'Đơn vị tính'} />
                        </InlineGrid>
                        <Text as="p" tone="subdued">
                          Đơn vị tính là Kilogram cho tất cả các sản phẩm.
                        </Text>
                      </>}
                  </Card>
                </FormLayout>
              </Form>
              <br />
              <br />
            </Layout.Section>

            <Layout.Section variant="oneThird">
              <Card padding={'400'}>
                <BlockStack gap="100">
                  <Text as="h3" variant="bodyMd" fontWeight="bold">
                    Tình trạng sản phẩm
                  </Text>

                  <ChoiceList title="" choices={[{
                label: 'Bản nháp',
                value: '0',
                helpText: 'Lưu nháp, chỉ admin mới có quyền xem và sửa. Và nó không xuất hiện trong danh sách bán.'
              }, {
                label: 'Đang bán',
                value: '1',
                helpText: 'Tất cả mọi người đều xem được và thêm được vào đơn hàng.'
              }]} selected={[productStatusOption.value]} onChange={([value]) => productStatusOption.onChange(value)} />
                </BlockStack>
              </Card>

              <br />
              <Card padding={'400'}>
                <BlockStack gap="100">
                  <Text as="h3" variant="bodyMd" fontWeight="bold">
                    * Chuyên mục
                  </Text>
                  <Suspense fallback={<Text as="p">Đang tải...</Text>}>
                    <QuickSearchProductCategory2 label="Chọn một chuyên mục" current_category_id={entity?.product_to_category.map(el => el.category_id) ?? []} errorMessage={useFields.product_category.error} onClose={category_ids => {
                  useFields.product_category.onChange(category_ids);
                }} />
                  </Suspense>
                </BlockStack>
              </Card>

              <br />
              <Card padding={'400'}>
                <BlockStack gap="100">
                  <Text as="h3" variant="bodyMd" fontWeight="bold">
                    Nhãn hiệu
                  </Text>
                  <QuickSearchProductBrand allowMultiple={false} current_brand_id={[entity?.product_to_brand?.product_brand?.brand_id || '']} onClose={brand_ids => {
                useFields.product_brand.onChange(brand_ids[0]);
              }} label={''} />
                </BlockStack>
              </Card>

              <br />
              <Card padding={'400'}>
                <BlockStack gap="200">
                  <Text as="h3" variant="bodyMd" fontWeight="bold">
                    Từ khóa
                  </Text>
                  <InlineStack gap="200">
                    {useFields.product_tags.value === '' && <Text as="p" variant="bodyXs" tone="subdued">
                        Chưa có một từ khóa nào, để thêm, hãy gõ từ khóa và bấm enter.
                      </Text>}
                    {useFields.product_tags.value?.split(',').map((el, index) => {
                  if (!el) return undefined;
                  return <Tag onRemove={() => removeProductTagCallback(el)} key={`A_` + index}>
                          {el}
                        </Tag>;
                })}
                  </InlineStack>
                  <Form onSubmit={() => {
                let old = useFields.product_tags.value;
                let _new = old + ',' + productTagsValue;
                let _newA = helpers.uniqueArray(_new.split(','));
                useFields.product_tags.onChange(_newA.join(','));
                setProductTagsValue('');
              }}>
                    <TextField label="" placeholder="Gõ từ khóa và bấm Enter" autoComplete="off" value={productTagsValue} onChange={val => setProductTagsValue(val)} />
                  </Form>
                </BlockStack>
              </Card>
              <br />

              <Card padding={'400'}>
                <BlockStack gap="400">
                  <Text as="h3" variant="bodyMd" fontWeight="bold">
                    Hiển thị biểu tượng
                  </Text>

                  <Text as="p" variant="bodyXs" tone="subdued">
                    Các biểu tượng hiển thị trong danh sách sản phẩm và chi tiết sản phẩm, các biểu tượng này phụ thuộc vào giao diện.
                  </Text>
                  <br />

                  <ChoiceList allowMultiple title="" choices={[{
                label: 'Biểu tượng kim cương',
                value: 'has_diamond_extra'
              }, {
                label: 'Biểu tượng bán chạy',
                value: 'best_sellers'
              }, {
                label: 'Biểu tượng có khuyến mại',
                value: 'discount'
              }, {
                label: 'Biểu tượng có thưởng thêm',
                value: 'bonus'
              }]} selected={useFields.product_badge.value?.split(',') ?? []} onChange={e => useFields.product_badge.onChange(e.join(','))} />

                  <Divider />

                  <Checkbox label={'Đánh dấu sản phẩm ưa thích'} helpText={`Sản phẩm ưa thích sẽ được ưu tiên hiển thị.`} value={`1`} checked={useFields.is_sensitive.value === 1} onChange={e => useFields.is_sensitive.onChange(Number(e))} />
                </BlockStack>
              </Card>

              <br />
              {!helpers.isEmpty(settings?._product_super_meta) && <Card padding={'400'}>
                  <BlockStack gap="500">
                    <Text as="h3" variant="bodyMd" fontWeight="bold">
                      Thuộc tính mở rộng
                    </Text>
                    {settings?._product_super_meta?.map((attribute, index) => {
                const thisValue = useFields._product_super_meta.value.find(el => el.meta_key === attribute.key);

                /** tách ra để trigger cho nó phát hiện ra thay đổi */
                const handleChange = (value: string | boolean) => {
                  const updatedAttributes = useFields._product_super_meta.value.map(el => el.meta_key === attribute.key ? {
                    ...el,
                    meta_value: value.toString()
                  } : el);

                  // Nếu không tìm thấy giá trị nào thì? thì? thêm mới
                  if (!updatedAttributes.some(el => el.meta_key === attribute.key)) {
                    updatedAttributes.push({
                      meta_key: attribute.key,
                      meta_value: value.toString()
                    });
                  }

                  // Gọi onChange để Shopify useForm phát hiện thay đổi
                  useFields._product_super_meta.onChange(updatedAttributes);
                };
                return <FormLayout key={`superMeta_` + index}>
                          {attribute.type === 'boolean' ? <Checkbox label={attribute.label} checked={thisValue?.meta_value === 'true'} helpText={attribute.description} onChange={value => handleChange(value)} /> : <TextField label={attribute.label} type={attribute.type === 'number' ? 'number' : 'text'} helpText={attribute.description} value={thisValue?.meta_value || ''} autoComplete="off" onChange={value => handleChange(value)} />}
                        </FormLayout>;
              })}
                  </BlockStack>
                </Card>}

              <br />
            </Layout.Section>
          </Layout>

          <br />
          <br />
          <br />
          <br />
        </Page>}

      <FormPrompt hasUnsavedChanges={dirty} />

      <DialogConfirm tone="critical" show={showConfirmUnsavedForm} onClose={e => {
      e && history('/product');
      setShowConfirmUnsavedForm(false);
    }} title={'Rời khỏi trang mà chưa lưu thay đổi?'} content={'Rời khỏi trang này sẽ xóa tất cả các thay đổi chưa được lưu. Bấm hủy bỏ để tiếp tục chỉnh sửa.'} />
    </>;
}