import { useEffect, useState } from 'react';
import { Modal, TextField, Text, Form, BlockStack, Link } from '@shopify/polaris';
import __ from 'languages/index';
import { lengthLessThan, notEmptyString, useField, useForm } from '@shopify/react-form';
import { useCreatePaymentProfile, useMutateGetOnePaymentProfile, useUpdatePaymentProfile } from 'queries/payment_profile.query';
import __helpers from 'helpers/index';
import { useGetWebhookIncomings } from 'queries/webhook_incoming.query';
export default function PaymentCreateModal({
  onClose,
  current_payment_profile_id,
  show
}: {
  onClose: () => void;
  current_payment_profile_id: string;
  show: boolean;
}) {
  const {
    mutateAsync: getEntity,
    data: entity,
    reset,
    isPending: loading
  } = useMutateGetOnePaymentProfile();
  const {
    mutateAsync: updateEntity,
    isPending: updating,
    isSuccess: updateSuccess
  } = useUpdatePaymentProfile();
  const {
    mutateAsync: createEntity,
    isSuccess: createSuccess
  } = useCreatePaymentProfile();
  const {
    data: webhookIncomingData
  } = useGetWebhookIncomings({
    limit: 1,
    api_slug: 'payment-gate'
  });
  const [webhookUrl, setWebhookUrl] = useState<string>('');
  useEffect(() => {
    if (webhookIncomingData) {
      const webhookIncoming = webhookIncomingData.entities[0];
      if (webhookIncoming) {
        setWebhookUrl(process.env.REACT_APP_BACKEND_URL + `/webhook_incoming/payment-gate/${webhookIncoming.api_access_token}`);
      }
    }
  }, [webhookIncomingData]);
  useEffect(() => {
    if (current_payment_profile_id) {
      getEntity(current_payment_profile_id);
    }
  }, [current_payment_profile_id]);

  /**
   * Lấy URL ra... để query. chú ý phải load nội dung mới
   * trong useEffect
   */

  const useFields = {
    payment_name: useField<string>({
      value: entity?.payment_name ?? '',
      validates: [notEmptyString('Trường này không được để trống.'), lengthLessThan(155, 'Không được dài hơn 155 ký tự.')]
    }),
    payment_description: useField<string>({
      value: entity?.payment_description ?? '',
      validates: [lengthLessThan(255, 'Không được dài hơn 255 ký tự.')]
    }),
    payment_x_api_key: useField<string>({
      value: entity?.payment_x_api_key ?? '',
      validates: [lengthLessThan(155, 'Không được dài hơn 155 ký tự.'), notEmptyString('Trường này không được để trống.')]
    }),
    payment_x_client_id: useField<string>({
      value: entity?.payment_x_client_id ?? '',
      validates: [lengthLessThan(155, 'Không được dài hơn 155 ký tự.'), notEmptyString('Trường này không được để trống.'), inputValue => {
        if (__helpers.isUTF8(inputValue)) return 'Yêu cầu không sử dụng ký tự đặc biệt.';
      }]
    }),
    payment_checksum: useField<string>({
      value: entity?.payment_checksum ?? '',
      validates: []
    }),
    cancel_url: useField<string>({
      value: entity?.cancel_url ?? '',
      validates: [lengthLessThan(255, 'Không được dài hơn 255 ký tự.'), notEmptyString('Trường này không được để trống.')]
    }),
    success_url: useField<string>({
      value: entity?.success_url ?? '',
      validates: [lengthLessThan(255, 'Không được dài hơn 255 ký tự.'), notEmptyString('Trường này không được để trống.')]
    })
  };
  const {
    fields,
    submit,
    submitting,
    dirty,
    reset: resetForms,
    submitErrors,
    makeClean
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        // create new
        if (!entity) {
          await createEntity({
            payment_name: values.payment_name,
            payment_description: values.payment_description,
            payment_checksum: values.payment_checksum,
            payment_x_api_key: values.payment_x_api_key,
            payment_x_client_id: values.payment_x_client_id,
            cancel_url: values.cancel_url,
            success_url: values.success_url
          });
        } else {
          await updateEntity({
            id: current_payment_profile_id,
            payment_name: values.payment_name,
            payment_description: values.payment_description,
            payment_checksum: values.payment_checksum,
            payment_x_api_key: values.payment_x_api_key,
            payment_x_client_id: values.payment_x_client_id,
            cancel_url: values.cancel_url,
            success_url: values.success_url
          });
        }
      } catch (e: any) {
        if (e.params !== undefined) {
          if (e.params.field !== undefined) {
            useFields[e.params.field].setError(__('check_this_field_again'));
          }
        }
      }
      return {
        status: 'success'
      };
    }
  });
  useEffect(() => {
    if (updateSuccess) onCloseModal();
  }, [updateSuccess]);
  useEffect(() => {
    if (createSuccess) onCloseModal();
  }, [createSuccess]);
  useEffect(() => {
    if (!show) {
      resetForms();
      makeClean();
      reset();
    }
  }, [show]);
  function onCloseModal() {
    resetForms();
    onClose();
  }
  return <Modal open={show} onClose={onCloseModal} title={current_payment_profile_id ? 'Chỉnh sửa thông tin thanh toán' : 'Tạo profile thanh toán mới'} primaryAction={{
    content: current_payment_profile_id ? __('update_label_button') : 'Tạo ngay',
    disabled: loading,
    loading: loading || updating,
    onAction: submit
  }} secondaryActions={[{
    content: 'Hủy',
    onAction: onCloseModal
  }]}>
      <Modal.Section>
        <Form onSubmit={() => {
        submit();
      }}>
          <BlockStack gap="400">
            <Text as="p">Thanh toán qua cổng payOS, vui lòng truy cập payOS.vn để tạo profile thanh toán, sau đó chép các thông tin vào đây.</Text>

            <TextField autoFocus autoComplete="off" requiredIndicator label="Tên profile" helpText={`Bạn đặt tên cho thanh toán này để dễ quản lý.`} {...fields.payment_name} />
            <TextField autoComplete="off" multiline={2} maxLength={255} label="Mô tả" helpText={`Mô tả thêm cho payment profile này.`} {...fields.payment_description} />
            <TextField label="X Client ID" requiredIndicator autoComplete="off" {...fields.payment_x_client_id} />
            <TextField label="X API Key" requiredIndicator autoComplete="off" {...fields.payment_x_api_key} />

            <TextField label="Mã Checksum" autoComplete="off" {...fields.payment_checksum} />

            <TextField label="URL hủy thanh toán" helpText={`Khi khách hàng từ chối thanh toán, PayOS sẽ chuyển hướng lại URL này.`} autoComplete="off" requiredIndicator placeholder="https://" {...fields.cancel_url} />
            <TextField label="URL thanh toán thành công" helpText={`Khi khách hàng thanh toán thành công, payOS sẽ quay trở lại URL này.`} placeholder="https://" autoComplete="off" requiredIndicator {...fields.success_url} />
            <Text as="p">
              Khi cài đặt trên PayOS, bạn cũng cần phải điền đường dẫn webhook để payOS có thể gửi lại kết quả thanh toán. Đường link webhook này có
              thể tìm và cài đặt trong phần <Link url="/incoming_webhook">Incoming Webhook</Link>.
            </Text>
            <TextField label="" readOnly value={webhookUrl} autoComplete="off" />
          </BlockStack>
        </Form>
      </Modal.Section>
    </Modal>;
}