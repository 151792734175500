import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, DataTable, Icon, Link, Page, Text } from '@shopify/polaris';
import Pagination from 'components/pagination';
import helpers from 'helpers/index';
import { useDeleteConfirm } from 'context/DeleteConfirmContext';
import { Helmet } from 'react-helmet-async';
import SimpleFilter from 'components/simpleFilter';
import { ___ } from 'languages/index';
import { ArchiveIcon } from '@shopify/polaris-icons';
import dateandtime from 'date-and-time';
import PaymentCreateModal from './paymentCreateModal';
import { TypedPayment_profile, useDeletePaymentProfile, useGetPaymentProfile } from 'queries/payment_profile.query';
export default function Payment() {
  const {
    mutate: deleteEntity
  } = useDeletePaymentProfile();
  const rootSearchPath = window.location.search;
  let StringQuery: any = helpers.ExtractUrl(rootSearchPath) || false;
  const initialQuery = {
    query: '',
    page: 1,
    limit: 40,
    sort: 'createdAt:desc'
  };
  const [mainQuery, setMainQuery] = useState<any>({
    ...initialQuery,
    ...StringQuery
  });
  const {
    showDeleteConfirm
  } = useDeleteConfirm();
  const handleDelete = useCallback(async stock_id => {
    const confirmed = await showDeleteConfirm('Xóa thông tin thanh toán này?', 'Toàn bộ thông tin liên quan tới thanh toán này có thể bị xóa bỏ cùng.');
    if (confirmed) {
      // Xử lý hành động xóa
      deleteEntity(stock_id);
    }
  }, []);
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      return setMainQuery(initialQuery);
    } else {
      setMainQuery(oldValue => {
        return {
          ...oldValue,
          ..._value
        };
      });
    }
  }, []);
  const reduceRequest = useCallback(mainQuery => {
    getEntities();
    return mainQuery;
  }, []);
  const reduceRequestMemo = useMemo(() => {
    return helpers.debounce(_value => {
      reduceRequest?.call(this, _value);
    }, 500);
  }, []);

  /**
   * Change page number
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: numPage,
      limit: limit
    }));
  }, []);
  const {
    data,
    isLoading: loading,
    isFetched,
    refetch: getEntities
  } = useGetPaymentProfile(mainQuery);
  const [totalItems, setTotalItems] = useState(0);
  const [records, setRecords] = useState<TypedPayment_profile[]>([]);
  useEffect(() => {
    if (data) {
      let {
        entities,
        totalItems
      } = data;
      setRecords(entities);
      setTotalItems(totalItems);
    }
  }, [data]);
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (window.location.search !== buildURLSearch) {
      window.history.replaceState(null, 'Payment', '/payment' + buildURLSearch);
    }
    reduceRequestMemo();
  }, [mainQuery]);
  const [showModal, setShowModal] = useState(false);
  const [currentID, setCurrentID] = useState(null);
  const items = (item: TypedPayment_profile) => {
    const {
      id,
      payment_name,
      payment_x_api_key,
      payment_x_client_id,
      cancel_url,
      success_url,
      payment_description,
      createdAt
    } = item;
    return [<Text as="span">{id}</Text>, <div>
        <Text as="h3" fontWeight="bold" tone="caution">
          <span>{payment_name}</span>
        </Text>
        <Text as="p" tone="subdued" variant="bodyXs">
          {payment_description}
        </Text>
      </div>, <Text as="span">{payment_x_client_id}</Text>, <Text as="span">{payment_x_api_key}</Text>, dateandtime.format(new Date(Number(createdAt)), 'HH:mm:ss DD-MM-YYYY'), <Link onClick={() => {
      setCurrentID(id);
      setShowModal(true);
    }}>
        Chỉnh sửa
      </Link>, <Link onClick={() => handleDelete(id)}> Xóa bỏ</Link>];
  };
  return <>
      <Helmet>
        <title>Danh sách cổng thanh toán</title>
      </Helmet>

      <PaymentCreateModal onClose={() => {
      setShowModal(false);
      setCurrentID(null);
    }} current_payment_profile_id={currentID} show={showModal} />

      <Page title="Quản lý thanh toán" secondaryActions={[{
      content: 'Tạo profile mới',
      icon: ArchiveIcon,
      onAction: () => {
        setCurrentID(null);
        setShowModal(true);
      }
    }]}>
        <Card padding={'0'}>
          <SimpleFilter onCallback={filterCallback} options={[]} loading={false} sortField={[]} />
          <DataTable verticalAlign="middle" hideScrollIndicator stickyHeader={false} columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text', 'text']} headings={['ID', 'Tên', 'X Client ID', 'X API Key', 'Tạo lúc', '', '']} rows={records?.map(items)} footerContent={___('Show page {display_page_number} of {total_record_number} results', {
          display_page_number: <Text as="strong">{mainQuery?.page || 1}</Text>,
          total_record_number: <Text as="strong">{totalItems}</Text>
        })} hasZebraStripingOnData increasedTableDensity />
        </Card>

        <br />
        {totalItems > 0 ? <Pagination TotalRecord={totalItems} onChange={onChangePagination} pageSize={Number(mainQuery?.limit)} activeCurrentPage={Number(mainQuery?.page)} /> : null}
      </Page>
    </>;
}