import { Box, Card, Divider, InlineGrid, LegacyCard, Link, Page, Text, TextField, BlockStack, useBreakpoints, Form, Button, ContextualSaveBar, Tooltip, Select, Checkbox } from '@shopify/polaris';
import { notEmpty, useField, useForm } from '@shopify/react-form';
import { useNotification } from 'context/NotificationContext';
import { useSettings } from 'context/SettingsContext';
import __ from 'languages/index';
import { useUpdateSetting } from 'queries/setting.query';
import { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { RefreshIcon } from '@shopify/polaris-icons';
import __helpers from 'helpers/index';
import helpers from 'helpers/index';
import { useCreateAssistants, useGetAIModels } from 'queries/ai.query';
var newwindow;
function createPop(url, name) {
  newwindow = window.open(url, name, 'width=560,height=540,toolbar=0,menubar=0,location=0');
  if (window.focus) {
    newwindow.focus();
  }
}
export default function Embed() {
  const {
    addNotification
  } = useNotification();
  const {
    smUp
  } = useBreakpoints();
  const {
    settings: entities
  } = useSettings();
  const {
    mutateAsync: updateEntity,
    error,
    isSuccess: updateSuccess,
    isPending: updating
  } = useUpdateSetting();
  const useFields = {
    zalo_secret_id: useField<string>({
      value: entities?.zalo_secret_id ?? '',
      validates: []
    }),
    zalo_app_id: useField<string>({
      value: entities?.zalo_app_id ?? '',
      validates: []
    }),
    _chatgpt_key: useField<string>({
      value: entities?._chatgpt_key ?? '',
      validates: []
    }),
    _chatgpt_model_selected: useField<string>({
      value: entities?._chatgpt_model_selected ?? 'gpt-4o-mini',
      validates: []
    }),
    _chatgpt_assistants_name: useField<string>({
      value: entities?._chatgpt_assistants_name ?? 'Trợ lý giám đốc',
      validates: []
    }),
    enable_ai_gen_social_post: useField<number>({
      value: Number(entities?.enable_ai_gen_social_post ?? 0),
      validates: []
    }),
    enable_product_assistant_feature: useField<number>({
      value: Number(entities?.enable_product_assistant_feature ?? 0),
      validates: []
    }),
    enable_support_assistant_feature: useField<number>({
      value: Number(entities?.enable_support_assistant_feature ?? 0),
      validates: []
    }),
    support_greeting_message: useField<string>({
      value: entities?.support_greeting_message ?? '',
      validates: []
    }),
    prompt_ai_gen_social_post: useField<string>({
      value: entities?.prompt_ai_gen_social_post ?? '',
      validates: []
    }),
    ipinfo_key: useField<string>({
      value: entities?.ipinfo_key ?? '',
      validates: []
    }),
    ipgeolocation_key: useField<string>({
      value: entities?.ipgeolocation_key ?? '',
      validates: []
    })
  };
  const {
    fields,
    submit,
    submitting,
    dirty,
    reset: resetForm,
    submitErrors,
    makeClean
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        await updateEntity({
          zalo_secret_id: values.zalo_secret_id,
          zalo_app_id: values.zalo_app_id,
          _chatgpt_key: values._chatgpt_key,
          _chatgpt_assistants_name: values._chatgpt_assistants_name,
          _chatgpt_model_selected: values._chatgpt_model_selected,
          enable_ai_gen_social_post: values.enable_ai_gen_social_post,
          enable_product_assistant_feature: values.enable_product_assistant_feature,
          enable_support_assistant_feature: values.enable_support_assistant_feature,
          support_greeting_message: values.support_greeting_message,
          /** ipinfo.io */
          ipinfo_key: values.ipinfo_key,
          ipgeolocation_key: values.ipgeolocation_key
        });
      } catch (e: any) {
        if (e.params !== undefined) {
          if (e.params.field !== undefined) {
            useFields[e.params.field].setError(__('check_this_field_again'));
          }
        }
      }
      return {
        status: 'success'
      };
    }
  });
  const changePhoneWebhookHash = useCallback(async () => {
    //_phonelog_webhook_hash
    let newHash = __helpers.getRandomHash(20);
    await updateEntity({
      _phonelog_webhook_hash: newHash
    });
  }, []);
  const refreshTokenSync = useCallback(async () => {
    // _sync_token
    let tokenHash = __helpers.getRandomHash(20) + '_' + __helpers.getRandomHash(20);
    await updateEntity({
      _sync_token: tokenHash
    });
  }, []);

  /**
   * Notification
   */

  useEffect(() => {
    if (error) {
      addNotification('error', error.message);
    }
  }, [error]);
  useEffect(() => {
    if (updateSuccess) addNotification('info', __('updated_successfully'));
  }, [updateSuccess]);
  const {
    mutateAsync: createAssistants,
    isPending: creatingAssistant,
    isSuccess: createAssistantSuccess
  } = useCreateAssistants();
  const {
    mutateAsync: getAIModels,
    data: AIMODEL
  } = useGetAIModels();
  useEffect(() => {
    getAIModels();
  }, []);
  const createAssistantsCallback = useCallback(async () => {
    // xóa thread đi không là lỗi...
    helpers.cookie_delete('ai_thread');
    try {
      await createAssistants();
    } catch (e) {}
  }, []);
  useEffect(() => {
    if (createAssistantSuccess) addNotification('info', __('updated_successfully'));
  }, [createAssistantSuccess]);
  return <>
      <Helmet>
        <title>Mã nhúng và kết nối</title>
      </Helmet>
      <Page title="Mã nhúng và kết nối">
        <Helmet>
          <title>Cài đặt mã nhúng và kết nối</title>
        </Helmet>

        <BlockStack gap={{
        xs: '800',
        sm: '400'
      }}>
          <InlineGrid columns={{
          xs: '1fr',
          md: '2fr 5fr'
        }} gap="400">
            <Box as="section" paddingInlineStart={{
            xs: '400',
            sm: '0'
          }} paddingInlineEnd={{
            xs: '400',
            sm: '0'
          }}>
              <BlockStack gap="400">
                <Text as="h3" variant="headingMd">
                  Mã nhúng cho web
                </Text>
                <Text as="p" variant="bodyMd">
                  Mã Passport.js là mã quan trọng, giúp các chức năng bên dưới hoạt động trơn tru và bảo vệ bạn khỏi Spambot tấn công.
                </Text>
              </BlockStack>
            </Box>
            <LegacyCard sectioned>
              <Text as="p" variant="bodySm" tone="subdued">
                Nhúng mã sau đây lên landing page của bạn, trước thẻ <code>&lt;/head&gt;</code> và để ở mọi trang.
              </Text>
              <TextField label=" " value={`<script crossorigin="anonymous" type="application/javascript" src="${process.env.REACT_APP_PUBLIC_URL}/passport.js"></script>`} onChange={() => {}} multiline={2} autoComplete="off" />
              <br />
              <Text as="p" variant="bodySm" tone="subdued">
                Nhúng mã sau đây lên landing page của bạn, trước thẻ <code>&lt;/body&gt;</code> và để ở trang bạn muốn tracking người dùng.
              </Text>
              <TextField label=" " value={`<script>
        var backend_url = '${process.env.REACT_APP_BACKEND_URL}/activity/web_activity'; 
        var requestee_option = {};
    </script>
    <script crossorigin="anonymous" defer="defer" type="application/javascript" src="${process.env.REACT_APP_PUBLIC_URL}/requestee.js"></script>`} onChange={() => {}} multiline={2} autoComplete="off" />
              <br />
              <Text as="p" variant="bodySm" tone="subdued">
                Nếu bạn muốn định nghĩa trang đích để tracking tốt hơn, bạn có thể đặt dữ liệu vào biến <br />
                <code>requestee_option.activity_target = 'YOUR_DEFINITION'; // định nghĩa tên trang đích </code> <br />
                <code>requestee_option.activity_referrer = 'YOUR_DEFINITION'; // định nghĩa trang giới thiệu </code> <br />
                Khi đó bạn có thể dễ dàng định nghĩa và lọc được người dùng truy cập một cách dễ dàng.
              </Text>
            </LegacyCard>
          </InlineGrid>

          {smUp ? <Divider /> : null}

          <InlineGrid columns={{
          xs: '1fr',
          md: '2fr 5fr'
        }} gap="400">
            <Box as="section" paddingInlineStart={{
            xs: '400',
            sm: '0'
          }} paddingInlineEnd={{
            xs: '400',
            sm: '0'
          }}>
              <BlockStack gap="400">
                <Text as="h3" variant="headingMd">
                  Contact form
                </Text>
                <Text as="p" variant="bodyMd">
                  Form liên hệ để kết nối giữa khách hàng và doanh nghiệp thông qua website hoặc app.
                </Text>
              </BlockStack>
            </Box>
            <Card roundedAbove="sm">
              <BlockStack gap={'200'}>
                <Text as="h3" variant="headingSm">
                  Dành cho website
                </Text>
                <Text as="p">Bạn thực hiện truy vấn như sau:</Text>
                <pre>
                  {`URL: ${process.env.REACT_APP_BACKEND_URL}/contactform
METHOD: POST
BODY: {
    contactform_title: String,
    contactform_content: String,
    contactform_email: String,
    // hoặc
    contactform_name: String
}`}
                </pre>
                <Text as="p">
                  {' '}
                  Để có thể lưu một Contact Form trên website, bạn cần có mã nhúng ở phần 1. Mã nhúng sẽ giúp định danh khách hàng kể cả khi họ không
                  đăng nhập.{' '}
                </Text>

                <Text as="h3" variant="headingSm">
                  Dành cho mobile app
                </Text>
                <Text as="p">
                  Bạn thực hiện truy vấn như trên phiên bản web, tuy nhiên yêu cầu người dùng đăng nhập vì trên mobile app không sử dụng được thư viện
                  Passport.js
                </Text>
              </BlockStack>
            </Card>
          </InlineGrid>

          {smUp ? <Divider /> : null}

          <InlineGrid columns={{
          xs: '1fr',
          md: '2fr 5fr'
        }} gap="400">
            <Box as="section" paddingInlineStart={{
            xs: '400',
            sm: '0'
          }} paddingInlineEnd={{
            xs: '400',
            sm: '0'
          }}>
              <BlockStack gap="400">
                <Text as="h3" variant="headingMd">
                  Kết nối
                </Text>
                <Text as="p" variant="bodyMd">
                  Đây là các cổng kết nối từ các phần mềm ngoài vào trong CRM
                </Text>
              </BlockStack>
            </Box>
            <Card roundedAbove="sm">
              <BlockStack gap={'200'}>
                <Text as="h3" variant="headingSm">
                  Dành cho SMS Gate
                </Text>
                <Text as="p">
                  Để nhận SMS từ SMS-Box, vui lòng xem trong phần <Link url={'/incoming_webhook'}>incoming webhook</Link>. Bạn tìm tới webhook có tên{' '}
                  <i>SMS Gate</i>.
                </Text>
              </BlockStack>
            </Card>
          </InlineGrid>

          {smUp ? <Divider /> : null}

          <InlineGrid columns={{
          xs: '1fr',
          md: '2fr 5fr'
        }} gap="400">
            <Box as="section" paddingInlineStart={{
            xs: '400',
            sm: '0'
          }} paddingInlineEnd={{
            xs: '400',
            sm: '0'
          }}>
              <BlockStack gap="400">
                <Text as="h3" variant="headingMd">
                  Danh bạ
                </Text>
                <Text as="p" variant="bodyMd">
                  Cấu hình truy vấn danh bạ cho các loại tổng đài
                </Text>
              </BlockStack>
            </Box>
            <Card roundedAbove="sm">
              <BlockStack gap={'200'}>
                <Text as="h3" variant="headingSm">
                  Query danh bạ cho tổng đài
                </Text>
                <Text as="p">
                  Để kết nối tổng đài cho phần danh bạ, vui lòng xem trong phần <Link url={'/incoming_webhook'}>incoming webhook</Link>. Bạn tìm tới
                  webhook có tên <i>Contact Query</i>.
                </Text>
              </BlockStack>
              <br />
              <br />
              <BlockStack gap={'200'}>
                <Text as="h3" variant="headingSm">
                  FreePBX CRM Link
                </Text>
                <Text as="p">
                  The CRM Link module is designed to connect your CRM software to FreePBX. This allows FreePBX to push call history and caller
                  information, as well as call recordings and voicemails, to your CRM. And, in conjunction with the Desktop & Mobile Integration
                  plugin, allow inbound Call Popups and Click to Call functionality from your CRM.{' '}
                  <a href="https://www.freepbx.org/add-on/customer-relationship-management-crm-link/" rel="noreferrer" target="_blank">
                    Read more here.
                  </a>
                </Text>
                <Text as="h3" variant="headingSm">
                  Bạn đăng ký một webhook với direction "both" tới url:
                </Text>
                <TextField label="Webhook URL" value={`https://{backend_url}/phonelog/webhook_callback/${entities?._phonelog_webhook_hash || '{mã truy cập}'}`} connectedRight={<Tooltip content={'Tạo mã truy cập mới'}>
                      <Button icon={RefreshIcon} onClick={() => changePhoneWebhookHash()}></Button>
                    </Tooltip>} autoComplete="off" readOnly />
              </BlockStack>
            </Card>
          </InlineGrid>

          {smUp ? <Divider /> : null}

          <InlineGrid columns={{
          xs: '1fr',
          md: '2fr 5fr'
        }} gap="400">
            <Box as="section" paddingInlineStart={{
            xs: '400',
            sm: '0'
          }} paddingInlineEnd={{
            xs: '400',
            sm: '0'
          }}>
              <BlockStack gap="400">
                <Text as="h3" variant="headingMd">
                  Zalo Notification Service
                </Text>
                <Text as="p" variant="bodyMd">
                  Kết nối ứng dụng của bạn với ZNS (Zalo Notification Service)
                </Text>
              </BlockStack>
            </Box>
            <Card roundedAbove="sm">
              <Form onSubmit={submit}>
                <BlockStack gap={'200'}>
                  <Text as="h3" variant="headingSm">
                    Zalo Notification Service
                  </Text>
                  <TextField label="ID ứng dụng" autoComplete="off" {...fields.zalo_app_id} />
                  <TextField label="Khóa bí mật của ứng dụng" autoComplete="off" {...fields.zalo_secret_id} />
                  <TextField label="Đường link callback" readOnly autoComplete="off" value={window.location.origin + `/zaloZNSApp_callback`} helpText={'Đây là đường link "Thiết lập đường dẫn yêu cầu cấp quyền" trong Zalo For Deverlopment. Thêm đường dẫn này vào trong ứng dụng của Zalo để đường link hoạt động.'} />
                  <Text as="p">
                    Khi bạn đã có ID ứng dụng và Khóa bí mật, bạn cần cấp quyền cho ứng dụng của mình. Click vào nút bên dưới để cấp quyền.
                  </Text>
                  <br />
                  <Text as="p">
                    Tài liệu phát triển ứng dụng Zalo, truy cập{' '}
                    <a href="https://developers.zalo.me/apps" rel="noreferrer" target="_blank">
                      Tại đây
                    </a>
                    .
                  </Text>
                  <br />

                  <Button target="_blank" onClick={() => {
                  return createPop(`https://oauth.zaloapp.com/v4/oa/permission?app_id=${entities?.zalo_app_id}&redirect_uri=${window.location.origin}/zaloZNSApp_callback`, 'Zalo Permission');
                }} url={null}>
                    Xin cấp Quyền
                  </Button>
                </BlockStack>
              </Form>
            </Card>
          </InlineGrid>

          {smUp ? <Divider /> : null}

          <InlineGrid columns={{
          xs: '1fr',
          md: '2fr 5fr'
        }} gap="400">
            <Box as="section" paddingInlineStart={{
            xs: '400',
            sm: '0'
          }} paddingInlineEnd={{
            xs: '400',
            sm: '0'
          }}>
              <BlockStack gap="400">
                <Text as="h3" variant="headingMd">
                  Đồng bộ hóa
                </Text>
                <Text as="p" variant="bodyMd">
                  Đồng bộ với các hệ thống khác, ví dụ như Wordpress.org
                </Text>
              </BlockStack>
            </Box>
            <Card roundedAbove="sm">
              <BlockStack gap="400">
                <Text as="p">
                  Để đồng bộ hóa, vui lòng kết nối tới <code>{`/sync/{path}`}</code> để sync các dữ liệu quan trọng. Để kết nối được, truy vấn như
                  sau:
                </Text>

                <pre>
                  {`GET /sync
HEADER: {
x-signed: ${entities?._sync_token || 'Mã đồng bộ'}
}
`}
                </pre>
                <Text as="p">
                  Trong đó: Mã <code>x-signed</code> phải được bao gồm.
                </Text>
                <Button icon={RefreshIcon} onClick={() => refreshTokenSync()}>
                  Lấy mã mới
                </Button>
              </BlockStack>
            </Card>
          </InlineGrid>

          {smUp ? <Divider /> : null}

          <InlineGrid columns={{
          xs: '1fr',
          md: '2fr 5fr'
        }} gap="400">
            <Box as="section" paddingInlineStart={{
            xs: '400',
            sm: '0'
          }} paddingInlineEnd={{
            xs: '400',
            sm: '0'
          }}>
              <BlockStack gap="400">
                <Text as="h3" variant="headingMd">
                  ChatGPT key
                </Text>
                <Text as="p" variant="bodyMd">
                  Key của dịch vụ openai.com
                </Text>
              </BlockStack>
            </Box>
            <Card roundedAbove="sm">
              <BlockStack gap={'400'}>
                <TextField label="Key kết nối" autoComplete="off" {...fields._chatgpt_key} />
                <TextField label="Đặt tên cho trợ lý" autoComplete="off" {...fields._chatgpt_assistants_name} />
                <TextField label="Mã trợ lý" connectedRight={<Button onClick={() => createAssistantsCallback()} loading={creatingAssistant}>
                      Tạo một trợ lý
                    </Button>} autoComplete="off" readOnly value={entities?._chatgpt_assistants || 'Chưa có'} />
                <Select options={AIMODEL?.map(models => ({
                label: models.id,
                value: models.id
              }))} label={'Chọn Models'} {...fields._chatgpt_model_selected} />
                <Checkbox label={'Cho phép tạo bài viết với AI'} checked={useFields.enable_ai_gen_social_post.value === 1} onChange={e => useFields.enable_ai_gen_social_post.onChange(Number(e))} />
                <Divider />
                <Checkbox label={'Cho phép tư vấn sản phẩm với AI'} checked={useFields.enable_product_assistant_feature.value === 1} onChange={e => useFields.enable_product_assistant_feature.onChange(Number(e))} />
                <Divider />
                <Checkbox label={'Cho phép chat tư vấn, hỗ trợ với AI'} checked={useFields.enable_support_assistant_feature.value === 1} onChange={e => useFields.enable_support_assistant_feature.onChange(Number(e))} />
                <Text as="h3">Tin nhắn chào của trợ lý tư vấn, hỗ trợ hệ thống</Text>
                <TextField label="" autoComplete="off" {...fields.support_greeting_message} />
                <Text as="p" tone="subdued">
                  Tin nhắn này sẽ được hiển thị cho người dùng khi lần đầu tiên họ vào phần tư vấn, hỗ trợ
                </Text>
                <Divider />
                <TextField label="Nội dung dùng làm mẫu khi tạo bài viết với AI (sử dụng dịch vụ ChatGPT)." autoComplete="off" multiline={true} {...fields.prompt_ai_gen_social_post} />
              </BlockStack>
            </Card>
          </InlineGrid>

          {smUp ? <Divider /> : null}

          <InlineGrid columns={{
          xs: '1fr',
          md: '2fr 5fr'
        }} gap="400">
            <Box as="section" paddingInlineStart={{
            xs: '400',
            sm: '0'
          }} paddingInlineEnd={{
            xs: '400',
            sm: '0'
          }}>
              <BlockStack gap="400">
                <Text as="h3" variant="headingMd">
                  IPInfo
                </Text>
                <Text as="p" variant="bodyMd">
                  Hệ thống cần lấy thông tin đăng nhập qua dịch vụ IPInfo
                </Text>
              </BlockStack>
            </Box>
            <Card roundedAbove="sm">
              <BlockStack gap="400">
                <Text as="p">
                  Hệ thống sử dụng dịch vụ của hai nhà cung cấp thông tin IP để lưu trữ thông tin khi đăng nhập nhằm đảm bảo an toàn cho hệ thống. Vui
                  lòng đăng ký và nhập key tại đây.
                </Text>
                <TextField label="Key IPINFO.IO" helpText={`Dịch vụ của IPInfo.io yêu cầu phải có key sử dụng, phân tách các key khác nhau bởi dấu phẩy(,)`} autoComplete="off" multiline={false} {...fields.ipinfo_key} />
                <TextField label="Key abstractapi.com" helpText={`Dịch vụ của abstractapi.com yêu cầu phải có key sử dụng, phân tách các key khác nhau bởi dấu phẩy(,)`} autoComplete="off" multiline={false} {...fields.ipgeolocation_key} />
              </BlockStack>
            </Card>
          </InlineGrid>

          {/* {smUp ? <Divider /> : null}
           <InlineGrid columns={{ xs: '1fr', md: '2fr 5fr' }} gap="400">
            <Box as="section" paddingInlineStart={{ xs: '400', sm: '0' }} paddingInlineEnd={{ xs: '400', sm: '0' }}>
              <BlockStack gap="400">
                <Text as="h3" variant="headingMd">
                  ------
                </Text>
                <Text as="p" variant="bodyMd">
                  ----- ----- ----
                </Text>
              </BlockStack>
            </Box>
            <Card roundedAbove="sm">------</Card>
           </InlineGrid> */}

          {/** END */}
        </BlockStack>

        <br />
        <br />
        <br />
        <br />
      </Page>

      {dirty && <ContextualSaveBar message="Thay đổi chưa được lưu" saveAction={{
      onAction: () => submit(),
      loading: updating
    }} discardAction={{
      onAction: () => resetForm()
    }} />}
    </>;
}