/** Tình trạng thanh toán */
export const ORDER_PAYMENT_STATUS_CONST = ['order_payment_status_unpaid', 'order_payment_status_partialpaid', 'order_payment_status_paid', 'order_payment_status_refunding', 'order_payment_status_refunded'];
export const ORDER_PAYMENT_STATUS = {
  '0': 'order_payment_status_unpaid',
  '1': 'order_payment_status_partialpaid',
  '2': 'order_payment_status_paid',
  '3': 'order_payment_status_refunding',
  '4': 'order_payment_status_refunded'
};
export const enum ENUM_PAYMENT_TYPE {
  Bank = 'bank',
  Cash = 'cash',
  Cod = 'cod',
}
export enum ORDER_STATUS_ENUM {
  AwaitingConfirmation = 9,
  AwaitingPickup = 10,
  AwaitingShipment = 11,
  Shipped = 12,
  AwaitingDelivery = 13,
  Delivered = 14,
  Returned = 15,
  OrderCancelled = 19,
  CustomerCancelled = 20,
}
export enum ORDER_PAYMENT_STATUS_ENUM {
  /*  chưa thanh toán */
  'UNPAID' = 0,
  /** Thanh toán một phần */
  'PARTIALPAID' = 1,
  /** Đã thanh toán */
  'PAID' = 2,
  /* Chờ hoàn tiền */
  'REFUNDING' = 3,
  /** Đã hoàn tiền */
  'REFUNDED' = 4,
}
export const PAYMENT_TYPE_CONST = ['bank', 'cash', 'cod'];
export const USER_ACTIVITY_LIST = ['TOUCH', 'BANKING', 'BUYGOOD', 'SOCIAL', 'SUBCRIBE', 'WEBSITE', 'CHECKIN', 'READEMAIL', 'EMAILOUT', 'EMAILIN', 'SMSOUT', 'SMSIN', 'CALLOUT', 'CALLIN', 'LOGIN', 'LOGINAPP', 'LOGINWEB', 'CONTACTFORM', 'SHOPPINGCART', 'REDEEM'];