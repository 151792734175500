import { Badge, BlockStack, Divider, Link, Modal, SkeletonBodyText, Text } from '@shopify/polaris';
import Capacity from 'components/Capacity';
import __helpers from 'helpers/index';
import { TypedTransactions } from 'interface/transaction.model';
import __ from 'languages/index';
import { useGetTransaction } from 'queries/transaction.query';
import React, { useCallback, useEffect, useState } from 'react';
import dateandtime from 'date-and-time';
export default function TransactionView({
  show,
  transaction_hash,
  onClose
}: {
  show: boolean;
  transaction_hash: string;
  onClose: () => void;
}) {
  const {
    mutateAsync: getTransactionViaHash,
    data,
    isPending,
    isError
  } = useGetTransaction();
  const [entity, setEntity] = useState<TypedTransactions[]>([]);
  useEffect(() => {
    if (transaction_hash) {
      getTransactionViaHash(transaction_hash);
    }
  }, [transaction_hash]);
  useEffect(() => {
    if (data) setEntity(data);
  }, [data]);
  return <Modal open={show} onClose={() => onClose()} title={'Tra cứu giao dịch'} primaryAction={{
    content: 'Đóng',
    onAction: () => onClose()
  }}>
      <Modal.Section>
        <Capacity current_user_can={'manage_transactions'} onFail={<>Bạn không có quyền truy cập.</>}>
          {isPending ? <SkeletonBodyText /> : <BlockStack gap="400">
              {entity?.map(el => {
            return <BlockStack gap="400">
                    <div>
                      {__('transaction_type_' + el.transaction_type)} tài khoản{' '}
                      {el.wallet_receiver && <Link url={'/customer/view/' + el.wallet_receiver.user.user_id}>
                          {el.wallet_receiver.user?.display_name || el.wallet_receiver.user?.user_phonenumber}
                        </Link>}
                    </div>
                    <Text as="p">
                      <strong>Giao dịch: </strong> {el.transaction_hash}
                    </Text>
                    <Text as="p">
                      <strong>Số tiền:</strong> {__helpers.formatNumber(el.amount)}
                    </Text>
                    <Text as="p">
                      <strong>Loại:</strong> {el.transaction_category} ({__('transaction_category_' + el.transaction_category)})
                    </Text>
                    <Text as="p">
                      <strong>Mã đối ứng:</strong> {el.transaction_reference}
                    </Text>
                    {el.creator_to_user && <Text as="p">
                        <strong>Tạo bởi:</strong>{' '}
                        {el.creator_to_user?.display_name ?? el.creator_to_user?.user_phonenumber ?? el.creator_to_user?.user_email ?? ''}
                      </Text>}
                    <Text as="p">
                      <strong>Tạo lúc:</strong> {el.createdAt ? dateandtime.format(new Date(Number(el.createdAt)), 'HH:mm:ss DD-MM-YYYY') : '-'}
                    </Text>
                    <Divider />
                  </BlockStack>;
          })}

              {!__helpers.isEmpty(entity) && <>
                  <Text as="p">
                    Ghi chú: <i>{entity?.pop().transaction_note}</i>
                  </Text>
                  <Text as="p">
                    Giao dịch của đơn hàng: <i>{String(entity?.pop().transaction_reference || '-')}</i>
                  </Text>
                </>}
            </BlockStack>}
        </Capacity>
        <br />
      </Modal.Section>
    </Modal>;
}