import { BlockStack, InlineStack, Modal, TextField, Button, Form, Banner, FormLayout } from '@shopify/polaris';
import __ from 'languages/index';
import { useCallback, useEffect, useState } from 'react';
import helpers from 'helpers/index';
import { useGenerateWebAuthLoginOption, useUserLogin, useVerifyWebAuthlogin } from 'queries/user.query';
import { lengthLessThan, lengthMoreThan, notEmptyString, useField, useForm } from '@shopify/react-form';
import { ViewIcon, HideIcon, PersonLockFilledIcon, KeyIcon } from '@shopify/polaris-icons';
import { startAuthentication, browserSupportsWebAuthn, platformAuthenticatorIsAvailable } from '@simplewebauthn/browser';

/**
 * Xác thực hai bước, quan trọng!
 */
export default function UserQuickloginModal({
  show = false
}: {
  show: boolean;
}) {
  const {
    mutateAsync: generateWebAuthLoginOption
  } = useGenerateWebAuthLoginOption();
  const {
    mutateAsync: loginFn,
    isPending: loading
  } = useUserLogin();
  const [showModal, setShowModal] = useState(false);
  const [viewPasswordMode, setViewPasswordMode] = useState(false);
  const __passport = (window as any).__passport || '';
  useEffect(() => {
    if (window.location.pathname !== '/login') {
      setShowModal(show);
    }
  }, [show]);
  const hideModal = useCallback(async () => {
    setShowModal(false);
  }, []);

  /**
   * Khai báo field cho form!
   */
  const useFields = {
    user_input: useField<string>({
      value: '',
      validates: [notEmptyString('Trường này không được để trống.'), lengthLessThan(50, 'Bạn điền quá dài!'), lengthMoreThan(6, 'Email/số điện thoại quá ngắn!'), inputValue => {
        if (!helpers.isPhoneNumber(inputValue)) if (!helpers.isEmail(inputValue)) {
          return 'Định dạng Email không hợp lệ! Vui lòng kiểm tra lại email của bạn!';
        }
        if (helpers.isUTF8(inputValue)) {
          return 'Trường này không nên có mã Unicode, bạn vui lòng kiểm tra lại!';
        }
      }]
    }),
    password: useField<string>({
      value: '',
      validates: [notEmptyString('Trường này không được để trống.'), lengthMoreThan(6, 'Mật khẩu quá ngắn!'), inputValue => {
        if (helpers.isUTF8(inputValue)) {
          return 'Không nên dùng mã Unicode trong mật khẩu của bạn!';
        }
      }]
    })
  };
  const {
    fields,
    submit,
    reset: resetForm,
    makeClean
  } = useForm({
    fields: useFields,
    async onSubmit(form) {
      await loginFn({
        user_input: form.user_input,
        password: form.password,
        device_type: 'website',
        device_signature: 'a_ignore' /** For notification, but website doesn't need it ... */,
        device_uuid: __passport
      }).then(({
        data
      }: any) => {
        let {
          access_token,
          refresh_token,
          expires_at
        } = data;
        helpers.cookie_set('AT', access_token, 30);
        helpers.cookie_set('RT', refresh_token, 30);
        helpers.cookie_set('EA', expires_at, 30);
        hideModal();
      }).catch(e => {
        setInternalErrorShow(e?.message);
      });
      return {
        status: 'success'
      };
    }
  });
  useEffect(() => {
    resetForm();
    makeClean();
  }, []);

  /** BIO */
  const [waiting_for_webauthn, setWaiting_for_webauthn] = useState(false);
  const [internalErrorShow, setInternalErrorShow] = useState('');
  const clearInternalError = useCallback(() => {
    setInternalErrorShow('');
  }, []);
  const {
    mutateAsync: verifyWebAuthlogin
  } = useVerifyWebAuthlogin();
  const handleLoginByWebAuth = async () => {
    try {
      setWaiting_for_webauthn(true);
      const {
        data: options
      } = await generateWebAuthLoginOption();
      let {
        challenge
      } = options;
      const PublicKeyCredential = await startAuthentication({
        optionsJSON: options,
        useBrowserAutofill: false,
        verifyBrowserAutofillInput: false
      });
      const {
        data: verificationJSON
      } = await verifyWebAuthlogin({
        data: PublicKeyCredential,
        challenge: challenge,
        device_type: 'website',
        device_signature: 'a_ignore' /** For notification, but website doesn't need it ... */,
        device_uuid: __passport
      });
      if (verificationJSON.verified) {
        setInternalErrorShow('');
        let {
          access_token,
          refresh_token,
          expires_at
        } = verificationJSON.sessionData;
        helpers.cookie_set('AT', access_token, 30);
        helpers.cookie_set('RT', refresh_token, 30);
        helpers.cookie_set('EA', expires_at, 30);

        /** Đánh dấu lại là tên này có dùng biometric Authentication và có thể dùng cho các giao dịch sau ... */
        helpers.cookie_set('BA', '1', 365);
        hideModal();
      } else {
        setInternalErrorShow(__('login_via_webauthn_fail'));
        helpers.cookie_set('BA', '0', 365);
        setWaiting_for_webauthn(false);
      }
    } catch (error) {
      console.error('Error during authentication:', error);
      setInternalErrorShow(__('login_fail_this_device_has_not_registered_yet'));
      setWaiting_for_webauthn(false);
    }
  };
  return <>
      <Modal activator={null} open={showModal} onClose={hideModal} title={`Yêu cầu đăng nhập`}>
        <Modal.Section>
          {internalErrorShow && <>
              <Banner tone="critical" onDismiss={clearInternalError}>
                {internalErrorShow}
              </Banner>
              <br />
            </>}

          <>
            <Form onSubmit={submit}>
              <BlockStack gap="400">
                <FormLayout>
                  <TextField type="text" disabled={loading} placeholder="" label={__('login_by_your_account')} {...fields.user_input} requiredIndicator autoComplete="off" />
                  <TextField disabled={loading} label={__('login_by_your_password')} placeholder="******" type={viewPasswordMode ? 'text' : 'password'} requiredIndicator autoComplete="off" suffix={<InlineStack blockAlign="center">
                        <Button variant="plain" onClick={() => setViewPasswordMode(!viewPasswordMode)} icon={viewPasswordMode ? ViewIcon : HideIcon}></Button>
                      </InlineStack>} {...fields.password} />
                </FormLayout>
                <Button icon={PersonLockFilledIcon} submit variant="primary" fullWidth disabled={loading} onClick={submit} key={'button_login_normal'} size="large">
                  {__('login_button_context')}
                </Button>
              </BlockStack>
            </Form>
            <BlockStack gap="400">
              <br />
              {browserSupportsWebAuthn() && platformAuthenticatorIsAvailable() && <Button size="large" icon={KeyIcon} fullWidth loading={waiting_for_webauthn} key="BA" onClick={handleLoginByWebAuth}>
                  {__('login_using_biometric_authentication_button')}
                </Button>}
            </BlockStack>
          </>
        </Modal.Section>
      </Modal>
    </>;
}