import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "queries";
import helpers from "helpers/index";
import queryClient from 'queries';

/**
 *   Interface/model file auto generate for Payment_profile
 *   Interface for back-end
 *   Model for front-end.
 *   They are the same!
 */
export interface TypedPayment_profile {
    id?: string;
    payment_name?: string;
    payment_description?: string;
    payment_x_client_id?: string;
    payment_x_api_key?: string;
    payment_checksum?: string;
    cancel_url?: string;
    success_url?: string;
    createdAt?: string;
}


type IQuery = TypedPayment_profile & IQueryParams;


/*
* List of all PaymentProfile
*/
export function useGetPaymentProfile(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["payment_profile/fetch_entity_list"],
        queryFn: () => axios.get<TypedPayment_profile[]>(`/payment_profile${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                entities: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}

export function useGetOnePaymentProfile(id: string) {
    return useQuery({
        queryKey: ['payment_profile/fetch_entity'],
        queryFn: () => axios.get<TypedPayment_profile>(`/payment_profile/${id}`).then((response) => response.data),
        retry: 1,
        refetchOnWindowFocus: false,
        // enabled: false,
    });
}

export function useMutateGetOnePaymentProfile() {
    return useMutation({
        mutationKey: ['payment_profile/fetch_entity'],
        mutationFn: (id: string | bigint) => axios.get<TypedPayment_profile>(`/payment_profile/${id}`).then((response) => response.data),
        retry: 1,
    });
}


export function useUpdatePaymentProfile() {
    return useMutation({
        mutationKey: ['payment_profile/update_entity'],
        mutationFn: ({ id, ...rest }: TypedPayment_profile) => axios.patch<TypedPayment_profile>(`/payment_profile/${id}`, helpers.cleanEntity(rest)).then((response) => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['payment_profile/fetch_entity_list'] });
            queryClient.invalidateQueries({ queryKey: ['payment_profile/fetch_entity'] });
        }
    });
}


export function useCreatePaymentProfile() {
    return useMutation({
        mutationKey: ['payment_profile/create_entity'],
        mutationFn: (entity: TypedPayment_profile) => axios.post<TypedPayment_profile>(`/payment_profile`, helpers.cleanEntity(entity)).then(response => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['payment_profile/fetch_entity_list'] });
        }
    });
}


export function useDeletePaymentProfile() {
    return useMutation({
        mutationKey: ['payment_profile/delete_entity'],
        mutationFn: (webhook_id: string) => axios.delete<any>(`/payment_profile/${webhook_id}`),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['payment_profile/fetch_entity_list'] });
        }

    });
}