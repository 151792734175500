import React, { useEffect, useState } from 'react';
import dateandtime from 'date-and-time';
import { DataTable, Link, Text } from '@shopify/polaris';
import helpers from 'helpers/index';
import { useGetTransactions } from 'queries/transaction.query';
import { TypedTransactions } from 'interface/transaction.model';
export default function OrderTransaction({
  order_id
}: {
  order_id: string;
}) {
  /**
   * Danh sách giao dịch
   */
  const {
    refetch: getEntities,
    data
  } = useGetTransactions({
    transaction_reference: order_id,
    // transaction_type: 'debit',
    sort: 'createdAt:desc',
    limit: 10
  });
  const [transactionsEntities, setTransactionsEntities] = useState<TypedTransactions[]>([]);
  useEffect(() => {
    if (data) {
      setTransactionsEntities(data.body);
    }
  }, [data]);
  useEffect(() => {
    getEntities();
  }, [order_id]);
  return <>
      {transactionsEntities && transactionsEntities.length > 0 ? <DataTable hasZebraStripingOnData increasedTableDensity hideScrollIndicator truncate columnContentTypes={['text', 'text', 'text', 'text']} headings={['Giá trị', 'Phương thức', 'Loại giao dịch', 'Người nộp', 'Ngày thêm']} rows={transactionsEntities?.map((transactionData: any, index) => {
      return [helpers.formatNumber(transactionData.amount, ','),
      // transactionData.wallet_receiver.wallet_type.wallet_sign,
      transactionData.transaction_type, transactionData.transaction_category, <Link url={`/customer/view/${transactionData.wallet_receiver.user.user_id}`}>
                {transactionData.wallet_receiver.user.display_name || transactionData.wallet_receiver.user.user_phonenumber || transactionData.wallet_receiver.user.user_email}
              </Link>, dateandtime.format(new Date(Number(transactionData.createdAt)), 'HH:mm:ss DD-MM-YYYY')];
    })} /> : <Text as="p" tone="subdued">
          Chưa có giao dịch nào.
        </Text>}
    </>;
}