import { useEffect } from 'react';
import { FormLayout, Modal, TextField, Text, Form, Select, Checkbox, BlockStack, Divider } from '@shopify/polaris';
import { useState, useCallback } from 'react';
import diachinh from 'config/diachinh.json';
import { lengthLessThan, lengthMoreThan, notEmptyString, useField, useForm } from '@shopify/react-form';
import __ from 'languages/index';
import { useCreateStock, useMutateGetStock, useUpdateStock } from 'queries/stock.query';
import QuickSearchPaymentProfile from 'components/quickSearchPaymentProfile';
export default function StockCreateModal({
  onClose,
  current_stock_id,
  show
}: {
  onClose: () => void;
  current_stock_id: string;
  show: boolean;
}) {
  const {
    mutate: getEntity,
    data: entity,
    isPending: loading
  } = useMutateGetStock();
  const {
    mutateAsync: updateEntity,
    isPending: updating,
    isSuccess: updateSuccess
  } = useUpdateStock();
  const {
    mutateAsync: createEntity,
    isSuccess: createSuccess
  } = useCreateStock();
  useEffect(() => {
    if (current_stock_id) {
      getEntity(current_stock_id);
    }
  }, [current_stock_id]);

  /**
   * Lấy URL ra... để query. chú ý phải load nội dung mới
   * trong useEffect
   */

  const useFields = {
    stock_id: useField<string>({
      value: entity?.stock_id ?? '',
      validates: []
    }),
    stock_name: useField<string>({
      value: entity?.stock_name ?? '',
      validates: [lengthLessThan(150, 'Không được dài hơn 150 ký tự.'), lengthMoreThan(1, 'Tên phải dài hơn 1 ký tự.'), notEmptyString('Trường này không được để trống.')]
    }),
    stock_code: useField<string>({
      value: entity?.stock_code ?? '',
      validates: [lengthLessThan(150, 'Không được dài hơn 150 ký tự.'), lengthMoreThan(1, 'Tên phải dài hơn 1 ký tự.'), notEmptyString('Trường này không được để trống.'), inputValue => {
        if (inputValue.match(/[^a-zA-Z0-9_-]+/gi)) return 'Yêu cầu không sử dụng ký tự đặc biệt.';
      }]
    }),
    stock_address: useField<string>({
      value: entity?.stock_address ?? '',
      validates: [lengthLessThan(255, 'No more than 255 characters.'), notEmptyString('Trường này không được để trống.')]
    }),
    manager: useField<string>({
      value: entity?.manager ?? '',
      validates: [notEmptyString('Trường này không được để trống.'), lengthLessThan(50, 'No more than 50 characters.')]
    }),
    hotline: useField<string>({
      value: entity?.hotline ?? '',
      validates: [inputValue => {
        if (!inputValue) return 'Yêu cầu phải có số điện thoại';
        if (inputValue.match(/\D+/gi)) return 'Phải là số điện thoại hợp lệ.';
      }, lengthLessThan(12, 'No more than 12 characters.'), notEmptyString('Trường này không được để trống.')]
    }),
    stock_address_city: useField<string>({
      value: entity?.stock_address_city ?? '',
      validates: [notEmptyString('Trường này không được để trống.')]
    }),
    stock_address_district: useField<string>({
      value: entity?.stock_address_district ?? '',
      validates: [notEmptyString('Trường này không được để trống.')]
    }),
    stock_address_ward: useField<string>({
      value: entity?.stock_address_ward ?? '',
      validates: [notEmptyString('Trường này không được để trống.')]
    }),
    is_default: useField<number>({
      value: entity?.is_default ?? 0,
      validates: []
    }),
    /* Hỗ trợ việc cài đặt kho  */
    payment_profile_id: useField<string>({
      value: String(entity?.payment_profile_id || ''),
      validates: []
    })
  };
  const {
    fields,
    submit,
    submitting,
    dirty,
    reset: resetForms,
    submitErrors,
    makeClean
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        // create new
        if (!values.stock_id) {
          await createEntity({
            stock_name: values.stock_name,
            stock_code: values.stock_code,
            stock_address: values.stock_address,
            manager: values.manager,
            hotline: values.hotline,
            stock_address_city: values.stock_address_city,
            stock_address_district: values.stock_address_district,
            stock_address_ward: values.stock_address_ward,
            payment_profile_id: values.payment_profile_id,
            is_default: Number(values.is_default)
          });
        } else {
          await updateEntity({
            stock_id: values.stock_id,
            stock_name: values.stock_name,
            stock_code: values.stock_code,
            stock_address: values.stock_address,
            manager: values.manager,
            hotline: values.hotline,
            stock_address_city: values.stock_address_city,
            stock_address_district: values.stock_address_district,
            stock_address_ward: values.stock_address_ward,
            payment_profile_id: values.payment_profile_id,
            is_default: Number(values.is_default)
          });
        }
      } catch (e: any) {
        if (e.params !== undefined) {
          if (e.params.field !== undefined) {
            useFields[e.params.field].setError(__('check_this_field_again'));
          }
        }
      }
      return {
        status: 'success'
      };
    }
  });
  useEffect(() => {
    if (updateSuccess) onCloseModal();
  }, [updateSuccess]);
  useEffect(() => {
    if (createSuccess) onCloseModal();
  }, [createSuccess]);
  useEffect(() => {
    resetForms();
    makeClean();
  }, [show]);
  function onCloseModal() {
    resetForms();
    onClose();
  }
  const customer_address_city_selectType = useFields.stock_address_city;
  const customer_address_district_selectType = useFields.stock_address_district;
  const customer_address_ward_selectType = useFields.stock_address_ward;

  /**
   * Địa chính
   * Chọn tỉnh, thành phố ...
   */
  const [diachinhCity, setDiachinhCity] = useState<string | undefined>(undefined);
  const [diachinhDistrict, setDiachinhDistrict] = useState<string | undefined>(undefined);
  const [diachinhDistrictList, setDiachinhDistrictList] = useState(undefined);
  const [diachinhWards, setDiachinhWards] = useState<string | undefined>('');
  const [diachinhWardsList, setDiachinhWardsList] = useState(undefined);

  // set default value
  useEffect(() => {
    if (!entity) return;
    setDiachinhCity(`` + entity?.stock_address_city || null);
    setDiachinhDistrict(`` + entity?.stock_address_district || null);
    setDiachinhWards(`` + entity?.stock_address_ward || null);
  }, [entity]);
  const diachinhCityCallback = useCallback((_value: string) => {
    setDiachinhCity(_value);
    customer_address_city_selectType.onChange(_value);
  }, []);
  const [diachinhCityList, setDiachinhCityList] = useState(null);
  useEffect(() => {
    let citys = [{
      label: 'Chọn Tỉnh/thành phố',
      value: ''
    }];
    for (let _city in diachinh) {
      let city = diachinh[_city];
      // @ts-ignore
      citys.push({
        label: city.name,
        value: city.code
      });
    }
    setDiachinhCityList(citys.sort((a, b) => a.label.localeCompare(b.label)));
  }, []);

  /**
   * Địa chính
   * Chọn Quận Huyện
   */
  const diachinhDistrictCallback = useCallback((_value: string) => {
    setDiachinhDistrict(_value);
    customer_address_district_selectType.onChange(_value);
  }, []);
  useEffect(() => {
    if (diachinhCity) {
      // setDiachinhDistrict(undefined);
      setDiachinhDistrictList(undefined);
      // setDiachinhWards(undefined);
      setDiachinhWardsList(undefined);
      let quanhuyens = [{
        label: 'Chọn Quận/ Huyện',
        value: ''
      }];
      for (let quanhuyen in diachinh?.[diachinhCity]?.['quan-huyen']) {
        let quanhuyendata = diachinh?.[diachinhCity]?.['quan-huyen']?.[quanhuyen];
        quanhuyens.push({
          label: quanhuyendata.name_with_type,
          value: quanhuyendata.code
        });
      }
      setDiachinhDistrictList(quanhuyens.sort((a, b) => a.label.localeCompare(b.label)));
    }
  }, [diachinhCity]);

  /**
   * Địa chính
   * Chọn xã Phường ...
   */
  const diachinhWardsCallback = useCallback((_value: string) => {
    setDiachinhWards(_value);
    customer_address_ward_selectType.onChange(_value);
  }, []);
  useEffect(() => {
    if (!diachinhCity || !diachinhDistrict) {
      setDiachinhWardsList(undefined);
      return;
    }
    if (typeof diachinh[diachinhCity]?.['quan-huyen']?.[diachinhDistrict] === 'undefined') {
      return;
    }
    let xathitrans = [{
      label: 'Chọn Xã/ Thị trấn',
      value: ''
    }];
    for (let xathitran in diachinh[diachinhCity]?.['quan-huyen']?.[diachinhDistrict]?.['xa-phuong']) {
      let quanhuyendata = diachinh[diachinhCity]?.['quan-huyen']?.[diachinhDistrict]?.['xa-phuong']?.[xathitran];
      xathitrans.push({
        label: quanhuyendata.name_with_type,
        value: quanhuyendata.code
      });
    }
    setDiachinhWardsList(xathitrans.sort((a, b) => a.label.localeCompare(b.label)));
  }, [diachinhDistrict, diachinhCity]);
  return <Modal open={show} onClose={onCloseModal} title={current_stock_id ? 'Chỉnh sửa kho hàng' : 'Tạo kho hàng'} primaryAction={{
    content: current_stock_id ? __('update_label_button') : 'Tạo ngay',
    disabled: loading,
    loading: loading || updating,
    onAction: submit
  }} secondaryActions={[{
    content: 'Hủy',
    onAction: onCloseModal
  }]}>
      <Modal.Section>
        <Form onSubmit={() => {
        submit();
      }}>
          <BlockStack gap="400">
            <FormLayout.Group condensed>
              <TextField autoFocus autoComplete="off" requiredIndicator label="Tên kho" {...fields.stock_name} />
              <TextField autoComplete="off" requiredIndicator label="Mã kho" {...fields.stock_code} />
            </FormLayout.Group>

            <TextField label="Địa chỉ" requiredIndicator autoComplete="off" {...fields.stock_address} />

            <FormLayout.Group condensed>
              <Select key={'ahjfkdgf'} label="Tỉnh/Thành phố" requiredIndicator {...useFields.stock_address_city} options={diachinhCityList} value={diachinhCity} onChange={diachinhCityCallback} />
              <Select key={'ahjfdsfsgkdgf'} label="Quận/ huyện" requiredIndicator {...useFields.stock_address_district} options={diachinhDistrictList} value={diachinhDistrict} onChange={diachinhDistrictCallback} />
              <Select key={'ahjfksdgsdgsw4dgf'} label="Xã/Thị trấn" requiredIndicator {...useFields.stock_address_ward} options={diachinhWardsList} value={diachinhWards} onChange={diachinhWardsCallback} />
            </FormLayout.Group>

            <Divider />
            <Text as="h3" variant="headingSm">
              Thông tin người quản lý
            </Text>
            <Divider />

            <TextField label="Tên người quản lý" autoComplete="off" requiredIndicator {...fields.manager} />
            <TextField label="Hotline" autoComplete="off" requiredIndicator {...fields.hotline} />

            <Divider />
            <Text as="h3" variant="headingSm">
              Thông tin thanh toán
            </Text>
            <Divider />
            <QuickSearchPaymentProfile current_payment_profile_id={String(entity?.payment_profile_id || '')} onClose={v => fields.payment_profile_id.onChange(v.id)} title={'Chọn payment profile'} />
            <Checkbox label="Đặt làm kho mặc định" checked={useFields.is_default.value === 1} onChange={e => useFields.is_default.onChange(Number(e))} />
          </BlockStack>
        </Form>
      </Modal.Section>
    </Modal>;
}