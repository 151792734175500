import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Sidebar from '../config/sidebar';
import Logo from 'media/images/logo.svg';
import { Text, Frame, Toast, Icon, TopBar, Link, Avatar, ResourceList, BlockStack, Box, ResourceItem, EmptyState, SkeletonBodyText, Form, TextField, Button } from '@shopify/polaris';
import { ExitIcon, ProfileIcon, MagicIcon, PhoneInIcon, ChatIcon, NotificationIcon, SettingsFilledIcon, PersonAddIcon } from '@shopify/polaris-icons';
import SidebarPopup from 'components/sidebarPopup';
import PhoneCallLog from './phoneCall';
import SMSLog from './smsLog';
import MyNotificationLog from './notificationLog';
import helpers from 'helpers/index';
import __ from 'languages/index';
import { useUserLogout } from 'queries/user.query';
import { useAuth } from 'context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useNotification } from 'context/NotificationContext';
import __helpers from 'helpers/index';
import Capacity from 'components/Capacity';
import { useSocket } from 'context/SocketContext';
import { useFloatingBanner } from 'context/FloatingBannerProvider';
import { useMutateCreateCustomer, useMutateSearchCustomer } from 'queries/customer.query';
import { useMutateGetOrders } from 'queries/orders.query';
import ChatGPT from './chatGPT';

// rootReducer.js

export default function AppFrame({
  children,
  hasSidebar = true
}: {
  children: any;
  hasSidebar?: boolean;
}) {
  const history = useNavigate();
  /**
   * Check login ...
   */

  const skipToContentRef = useRef(null);
  const {
    isAuthenticated,
    user: account
  } = useAuth();
  const [userMenuActive, setUserMenuActive] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const toggleUserMenuActive = useCallback(() => setUserMenuActive(_userMenuActive => !_userMenuActive), []);
  const toggleMobileNavigationActive = useCallback(() => setMobileNavigationActive(_mobileNavigationActive => !_mobileNavigationActive), []);
  const {
    mutateAsync: logMeOut,
    isSuccess: isLogoutSuccess
  } = useUserLogout();
  useEffect(() => {
    if (isLogoutSuccess) {
      history('/login');
    }
  }, [isLogoutSuccess]);
  const logOutCallback = useCallback(async () => {
    helpers.cookie_delete('AT');
    helpers.cookie_delete('RT');
    helpers.cookie_delete('EA');
    /** xóa app id để không dính lỗi SSO */
    localStorage.setItem('redirect', window.location.href);
    // load lại để log out ra ngoài ...
    await logMeOut();
  }, []);
  const userMenuMarkup = !helpers.isEmpty(account) ? <TopBar.UserMenu customWidth="250px" actions={[{
    items: [{
      content: __('my_profile'),
      url: '/profile',
      icon: ProfileIcon
    }, {
      content: __('edit_my_profile'),
      url: '/edit-my-profile',
      icon: SettingsFilledIcon
    }]
  }, {
    items: [{
      content: __('logout'),
      icon: ExitIcon,
      onAction: () => logOutCallback()
    }]
  }]} name={account?.display_name || account?.user_email || account?.user_login} detail={account?.user_email} initials={String(account?.display_name || account?.user_email || account?.user_login || 'unknown').charAt(0)} open={userMenuActive} avatar={account.user_avatar ? __helpers.getMediaLink(account.user_avatar) : null} onToggle={toggleUserMenuActive} /> : <TopBar.UserMenu actions={[{
    items: [{
      content: __('login'),
      url: '/login',
      icon: ExitIcon
    }, {
      content: __('register'),
      url: '/register',
      icon: ProfileIcon
    }]
  }]} name={__('welcome')} detail={__('customer')} initials="U" open={userMenuActive} onToggle={toggleUserMenuActive} />;

  /** Tìm kiếm */
  const {
    addNotification
  } = useNotification();
  const [searchActive, setSearchActive] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [customerName, setCustomerName] = useState('');
  const {
    mutateAsync: createCustomer,
    isPending: isCreatingCustomer,
    isSuccess: isCreateCustomerSuccess
  } = useMutateCreateCustomer();
  useEffect(() => {
    if (isCreateCustomerSuccess) {
      setCustomerName('');
      setSearchValue('');
      setSearchActive(false);
    }
  }, [isCreateCustomerSuccess]);
  const createNewCustomerCallback = useCallback(async () => {
    if (!customerName.trim()) return;
    try {
      let newCustomerData = await createCustomer({
        user_phonenumber: searchValue,
        display_name: customerName
      });
      addNotification('info', 'Khách hàng đã được tạo thành công');
      history(`/customer/view/${newCustomerData.user_id}`);
    } catch (error) {
      console.error(error);
    }
  }, [customerName, searchValue, createCustomer]);
  const handleSearchResultsDismiss = useCallback(() => {
    setSearchActive(false);
    setSearchValue('');
  }, []);
  const handleSearchFieldChange = useCallback(value => {
    setSearchValue(value);
    setSearchActive(value.length > 0);
  }, []);
  const {
    mutateAsync: searchCustomer,
    data: searchData,
    isPending: searching
  } = useMutateSearchCustomer();
  const {
    mutateAsync: searchOrder,
    data: orderSearchData
  } = useMutateGetOrders();
  const getSearchCallback = useCallback(async (searchValue: string) => {
    searchCustomer({
      query: searchValue,
      limit: 2
    });
    searchOrder({
      query: searchValue,
      limit: 2
    });
  }, []);
  const searchDebound = useMemo(() => helpers.debounce(_value => getSearchCallback?.call(this, _value), 500), []);
  useEffect(() => {
    if (!searchValue) {
      return;
    }
    // Do search here
    searchDebound(searchValue);
  }, [searchValue]);
  const emptyStateMarkup = useMemo(() => {
    const isPhoneNumber = /^[0-9]{10,11}$/.test(searchValue);
    if (searchData && orderSearchData && !searchData.length && !orderSearchData?.length) {
      if (isPhoneNumber) {
        return <Box padding="400">
            <BlockStack gap="400">
              <Text variant="headingMd" as="h2">
                Tạo khách hàng mới
              </Text>
              <Form onSubmit={createNewCustomerCallback}>
                <BlockStack gap="400">
                  <TextField label="Số điện thoại" labelHidden value={searchValue} disabled autoComplete="off" />
                  <TextField autoFocus label="Tên khách hàng" labelHidden placeholder="Tên khách hàng" value={customerName} onChange={t => setCustomerName(t)} autoComplete="off" />
                  <Button submit variant="primary" icon={PersonAddIcon} disabled={!customerName.trim()} loading={isCreatingCustomer}>
                    Tạo khách hàng
                  </Button>
                </BlockStack>
              </Form>
            </BlockStack>
          </Box>;
      }
      return <EmptyState heading="Không thấy kết quả nào" image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png">
          <p>Kiểm tra lại mã đơn hàng hoặc số điện thoại của khách.</p>
        </EmptyState>;
    }
    return undefined;
  }, [searchData, orderSearchData, customerName, searchValue, isCreatingCustomer]);
  const searchResultsMarkup = <Box width="100%" padding={'0'}>
      {searching ? <Box width="100%" padding="400">
          <SkeletonBodyText />
        </Box> : null}
      {!searching && searchData && searchData.length < 1 && orderSearchData && orderSearchData.length < 1 && emptyStateMarkup}

      <BlockStack gap={'200'}>
        {searchData && searchData.length > 0 && <ResourceList headerContent="Khách hàng" showHeader resourceName={{
        singular: 'customer',
        plural: 'customers'
      }} items={searchData || []} loading={searching} emptyState={emptyStateMarkup} renderItem={customer => {
        const {
          display_name,
          user_phonenumber,
          user_id,
          is_vip,
          customer_to_user
        } = customer;
        const media = <Avatar size="md" name={display_name} initials={is_vip ? 'VIP' : display_name.charAt(0)} />;
        return <ResourceItem id={user_id} url={`/customer/view/${user_id}`} onClick={() => setSearchActive(false)} media={media} accessibilityLabel={`View details for ${display_name}`}>
                  <Text variant="bodyMd" fontWeight="bold" as="h3">
                    {display_name}
                  </Text>
                  <div>
                    {user_phonenumber} - {customer_to_user.customer_order_count + ' đơn'}
                  </div>
                </ResourceItem>;
      }} />}
        {orderSearchData?.length > 0 && <ResourceList headerContent="Đơn hàng" showHeader resourceName={{
        singular: 'customer',
        plural: 'customers'
      }} items={orderSearchData || []} loading={searching} renderItem={order => {
        const {
          order_pnr,
          order_id,
          order_total_mustpay,
          customer_data
        } = order;
        return <ResourceItem id={order_id} url={`/order/view/${order_id}`} onClick={() => setSearchActive(false)} media={null} accessibilityLabel={`View details for ${order_pnr}`}>
                  <Text variant="bodyMd" fontWeight="bold" as="h3">
                    {order_pnr}
                  </Text>
                  <div>
                    Khách hàng <strong>{customer_data?.display_name}</strong> - Phải thanh toán {__helpers.formatNumber(order_total_mustpay) + ' VND'}
                  </div>
                </ResourceItem>;
      }} />}
      </BlockStack>
    </Box>;
  const searchFieldMarkup = <TopBar.SearchField onChange={handleSearchFieldChange} value={searchValue} placeholder="Tìm theo mã đơn hàng hoặc số điện thoại" />;

  /**
   * Nút show bẹn website
   */
  const [showPhoneCallLog, setShowPhoneCallLog] = useState(false);
  const [showSMSLog, setShowSMSLog] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [showChatAI, setShowChatAI] = useState(false);
  const phonelogMarkup = <TopBar.Menu key="OpenPhoneBook" activatorContent={<span>
          <Icon source={PhoneInIcon} />
        </span>} open={false} actions={[]} onOpen={() => setShowPhoneCallLog(true)} onClose={() => {}} />;
  const smsSidebarMenuActivator = <TopBar.Menu key="OpenSMS" activatorContent={<span>
          <Icon source={ChatIcon} />
        </span>} open={false} actions={[]} onOpen={() => {
    setShowSMSLog(true);
  }} onClose={() => {}} />;
  const notificationSidebarMenuActivator = <TopBar.Menu key="OpenNotification" activatorContent={<span>
          <Icon source={NotificationIcon} />
        </span>} open={false} actions={[]} onOpen={() => setShowNotification(true)} onClose={() => alert('close notification')} />;
  const aiChatNotification = <TopBar.Menu key="OpenChatAI" activatorContent={<span>
          <Icon source={MagicIcon} />
        </span>} open={false} actions={[]} onOpen={() => setShowChatAI(true)} onClose={() => {}} />;
  const topBarMarkup = <TopBar showNavigationToggle userMenu={userMenuMarkup} searchResultsVisible={searchActive} searchField={isAuthenticated && searchFieldMarkup} searchResults={searchResultsMarkup} onSearchResultsDismiss={handleSearchResultsDismiss} onNavigationToggle={toggleMobileNavigationActive} secondaryMenu={isAuthenticated ? [phonelogMarkup, smsSidebarMenuActivator, notificationSidebarMenuActivator, aiChatNotification] : []} />;
  const logo = {
    width: 150,
    topBarSource: Logo,
    url: '/',
    accessibilityLabel: 'nBee'
  };

  /** Global notification */
  const {
    notification,
    clearNotification
  } = useNotification();

  /** SOCKET  */
  const {
    socket
  } = useSocket(); // Lấy socket từ context
  const [messages, setMessages] = useState<{
    phonenumber: string;
    customer: string;
    customer_id: string | null;
    source: string;
  }>(null);
  useEffect(() => {
    if (socket) {
      // Lắng nghe sự kiện từ server
      socket.on('calling', (msg: {
        phonenumber: string;
        customer: string;
        customer_id: string | null;
        source: string;
      }) => {
        setMessages(msg);
      });
    }
    return () => {
      if (socket) {
        socket.off('calling'); // Hủy lắng nghe khi unmount
      }
    };
  }, [socket]);

  // const sendMessage = () => {
  //   if (socket && message.trim()) {
  //     socket.emit("messageFromClient", message); // Gửi tin nhắn tới server
  //     setMessage(""); // Reset input
  //   }
  // };
  const {
    showBanner
  } = useFloatingBanner();
  useEffect(() => {
    if (!messages) return;
    showBanner({
      title: messages.customer + ' đang gọi',
      content: <p>
          Cuộc gọi đến {messages?.source || 'hotline'} từ{' '}
          {messages?.customer_id ? <Link url={`/customer/view/${messages.customer_id}`}>{messages.phonenumber}</Link> : messages.phonenumber}
        </p>,
      duration: 15000
    });
  }, [messages, showBanner]);

  /** SOCKET  */

  /** Thông báo lần đầu khi vào app */
  useEffect(() => {
    setTimeout(() => {
      showBanner({
        title: 'Thông báo',
        content: <p>Một số thông báo quan trọng sẽ có mặt tại đây. Có những thông báo như cuộc gọi tới, có đơn hàng mới chờ xử lý ...</p>,
        duration: 8000
      });
    }, 1000);
  }, []);
  return <>
      <Frame topBar={topBarMarkup} logo={logo} navigation={hasSidebar && isAuthenticated && <Sidebar />} showMobileNavigation={mobileNavigationActive} onNavigationDismiss={toggleMobileNavigationActive} skipToContentTarget={skipToContentRef.current}>
        {notification && <Toast content={notification.message} duration={5000} onDismiss={() => clearNotification(notification.id)} error={notification.type === 'error'} />}
        <>
          <Capacity current_user_can={['manage_phonelog', 'view_phonelog']}>
            <SidebarPopup title="Phone call" show={showPhoneCallLog} onClose={() => setShowPhoneCallLog(false)}>
              <PhoneCallLog show={showPhoneCallLog} />
            </SidebarPopup>
          </Capacity>

          <Capacity current_user_can={['manage_sms']}>
            <SidebarPopup title="SMS" show={showSMSLog} onClose={() => setShowSMSLog(false)}>
              <SMSLog show={showSMSLog} />
            </SidebarPopup>
          </Capacity>
          {isAuthenticated && <>
              <SidebarPopup title="Thông báo" show={showNotification} onClose={() => setShowNotification(false)}>
                <MyNotificationLog />
              </SidebarPopup>
              <Capacity current_user_can={['allowed_use_ai_assistants']}>
                <SidebarPopup title="Trợ lý AI" show={showChatAI} onClose={() => setShowChatAI(false)}>
                  <ChatGPT />
                </SidebarPopup>
              </Capacity>
            </>}
        </>

        {children}
      </Frame>
    </>;
}