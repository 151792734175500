import { TypedUser } from "./user.model";

/**
 *   Interface/model file auto generate for Transactions
 *   Interface for back-end
 *   Model for front-end.
 *   They are the same!
 */
export interface TypedTransactions {
    transaction_id?: bigint | string;
    /** Phải chọn một loại ví, không thể nào làm khác được .... ví dụ đang tại giao dịch ví kim cương hay ví tiền? */
    wallet_id?: string;
    sender_wallet_id?: bigint | string;
    receiver_wallet_id?: bigint | string;
    receiver_user_id?: bigint | string;
    amount?: number; // DECIMA
    transaction_type?: string; // debit là rút tiền hoặc mất tiền, credit là thêm tiền vào hoặc được nhận.
    transaction_note?: string;
    transaction_category?: string;
    transaction_hash?: string;
    createdAt?: bigint;


    wallet_receiver?: {
        user: {
            user_id: bigint,
            display_name: string,
            user_phonenumber: string,
            user_email: string

        }
    },
    transaction_reference?: string | number;

    creator_to_user?: TypedUser

    // tạo
    debit_user_id?: string,
    credit_user_id?: string,
}

/**
 * commission: có order_id, hoa hồng bán hàng
 * passive: có order_id, thu nhập thụ động, hưởng lợi từ bậc thấp hơn bán hàng
 * progressive: có order_id, thu nhập affiliate lũy tiến, không rõ nhưng bậc bán hàng cao hơn bậc hưởng thì vào đây
 * reward: tiền thưởng đua top
 * bounty: Không có user_referrer: tiền thưởng nhờ giới thiệu khách hàng ...
 * bounty: có user_referrer: Tiền thưởng nhờ giới thiệu
 */

export const allowed_transaction_category = ["general", "commission", "passive", "payout", "payment", "redeem", "withdraw", "refund", "correction", "fee", "progressive", "reward", "bounty"];
// Step 2: Extract the keys
export type TypedAllowedTransactionCategory = typeof allowed_transaction_category[number];


export enum EnumTransactionCategory {
    GENERAL = 'general',
    COMMISSION = 'commission', // hoa hồng từ đơn hàng bán => trực tiếp
    PASSIVE = 'passive', // hoa hồng từ người giới thiệu bán => gián tiếp
    PAYOUT = 'payout',
    PAYMENT = "payment", // thanh toán ...
    REDEEM = 'redeem',
    WITHDRAW = 'withdraw',
    REFUND = 'refund',
    BOUNTY = 'bounty',
    CORRECTION = 'correction',
    FEE = 'fee',
    PROGRESSIVE = 'progressive',
    REWARD = 'reward',
};

