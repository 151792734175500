import { useCallback, useEffect } from 'react';
import { lengthLessThan, lengthMoreThan, notEmpty, useField, useForm } from '@shopify/react-form';
import { Form, Modal, TextField, BlockStack, Checkbox, Select, Text, ChoiceList } from '@shopify/polaris';
import __helpers from 'helpers/index';
import __ from 'languages/index';
import { useNotification } from 'context/NotificationContext';
import helpers from 'helpers/index';
import { TypedUser_role, useCreateUserRole, useUpdateUserRole } from 'queries/user_role.query';
import QuickSearchUserCapacityGroup from 'components/quickSearchUserCapacityGroup';
export default function UserRoleNewModal({
  open = false,
  entity,
  onClose
}: {
  open: boolean;
  onClose: Function;
  entity?: TypedUser_role;
}) {
  const {
    mutateAsync: createUserRole,
    isSuccess: createSuccesfully
  } = useCreateUserRole();
  const {
    mutateAsync: updateUserRole,
    isSuccess: updateSuccessfully
  } = useUpdateUserRole();
  const {
    addNotification
  } = useNotification();
  useEffect(() => {
    if (createSuccesfully) {
      addNotification('info', __('created_successfully'));
      closeModal();
    }
  }, [createSuccesfully]);
  useEffect(() => {
    if (updateSuccessfully) {
      addNotification('info', __('updated_successfully'));
      closeModal();
    }
  }, [updateSuccessfully]);
  const closeModal = useCallback(() => {
    onClose?.call(this, null);
    resetForm();
  }, []);
  const useFields = {
    user_role_title: useField<string>({
      value: entity?.user_role_title,
      validates: [notEmpty('Không được để trống trường này'), lengthLessThan(255, 'Không được quá 255 ký tự'), lengthMoreThan(1, 'Ít nhất phải 1 ký tự')]
    }),
    user_role: useField<string>({
      value: entity?.user_role,
      validates: [inputVal => {
        if (helpers.isUTF8(inputVal)) {
          return 'Chỉ cho phép A-Z, a-z và số, gạch dưới. Không sử dụng UTF-8.';
        }
      }]
    }),
    user_role_type: useField<string>({
      value: entity?.user_role_type ?? 'customer',
      validates: []
    }),
    user_capacity_group_id: useField<string>({
      value: entity?.user_capacity_group_id,
      validates: []
    }),
    is_default: useField<number>({
      value: entity?.is_default ?? 0,
      validates: []
    })
  };
  const {
    fields,
    submit,
    submitting,
    dirty,
    reset: resetForm
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        if (!entity) {
          await createUserRole({
            user_role_title: values.user_role_title,
            user_role: values.user_role,
            user_capacity_group_id: values.user_capacity_group_id,
            user_role_type: values.user_role_type,
            is_default: Number(values.is_default)
          });
        } else {
          await updateUserRole({
            id: entity?.id,
            user_role_title: values.user_role_title,
            user_role: values.user_role,
            user_capacity_group_id: values.user_capacity_group_id,
            user_role_type: values.user_role_type,
            is_default: Number(values.is_default)
          });
        }
      } catch (e: any) {
        if (e.params !== undefined) {
          if (e.params.field !== undefined) {
            useFields[e.params.field].setError(__('check_this_field_again'));
          }
        }
      }
      return {
        status: 'success',
        errors: []
      };
    }
  });
  useEffect(() => {
    resetForm();
  }, []);
  return <Modal open={open} title={'Thêm một user role'} onClose={closeModal} primaryAction={{
    content: 'Lưu lại',
    loading: submitting,
    disabled: !dirty,
    onAction: submit
  }} secondaryActions={[{
    content: 'Đóng lại',
    onAction: closeModal
  }]}>
      <Modal.Section>
        <Form onSubmit={submit}>
          <BlockStack gap={'400'}>
            <TextField autoFocus autoComplete="off" label="Tên vai trò" {...fields.user_role_title} />
            <TextField autoComplete="off" label="Slug" helpText={`Chỉ sử dụng chữ, số và gạch dưới.`} {...fields.user_role} />
            <QuickSearchUserCapacityGroup title={'Tìm một nhóm quyền'} current_capacity_group_id={entity?.user_capacity_group_id} onClose={el => useFields.user_capacity_group_id.onChange(el.capacity_group_id)} />

            <ChoiceList title={'Đây vai trò dành cho'} choices={[{
            label: __('customer'),
            value: 'customer'
          }, {
            label: __('staff'),
            value: 'staff'
          }]} selected={[useFields.user_role_type.value]} onChange={v => useFields.user_role_type.onChange(v[0])} />

            <Checkbox label="Đặt làm vai trò mặc định" checked={useFields.is_default.value === 1} onChange={e => useFields.is_default.onChange(Number(e))} />
          </BlockStack>
        </Form>
      </Modal.Section>
    </Modal>;
}